// Material UI
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const visibilityIcon = (state, onClick) => {
  return {
    component: state ? 
    <VisibilityOffIcon
      className="icon"
      onClick={onClick}
    /> : 
    <VisibilityIcon 
      className="icon"
      onClick={onClick}
    />,                                      
    direction: "right",
  }
}