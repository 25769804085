import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from "react-redux";
import { setInformativeMessage } from 'redux/actions/informativeMessage';
import { clearInformativeMessage } from 'redux/actions/informativeMessage';

// @mui material components
import { Card } from "@mui/material";

// Soft UI Dashboard React components
import PageLayout from 'examples/LayoutContainers/PageLayout';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Local components
import Bank from './components/Bank';
import Crypto from './components/Crypto';
import Buttons from './components/Buttons';
import Message from '../Status/Message';

// API
import { fetchWithdrawal } from 'api/transaction';
import { fetchWithdrawalLimit } from 'api/transaction';
import { ROUTES } from 'constants';

// sweetalert2 components
import Swal from "sweetalert2";

export default function Withdrawal() {

  const currentUser = useSelector(state => state.currentUser.data);
  const { message } = useSelector(state => state.informativeMessage);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { iban, wallet } = currentUser;
  const [withdrawal, setWithdrawal] = useState({ type: iban || wallet, amount: 0.0 });
  const [limit, setLimit] = useState(0);

  const showAlert = (success, text) => {
    Swal.fire({
      icon: success ? "success" : "error",
      title: success ? "Done" : "Sorry",
      text,
    }).then(() => {
      navigate(ROUTES.PROFILE);
    });
  };

  const getLimit = async () => {
    try {
      const { success, data } = await fetchWithdrawalLimit();
      if (success) {
        data <= 0 && showAlert(false, "Insufficient funds. Please make a deposit to proceed.");
        setLimit(parseFloat(data < 0 ? 0 : data));
        setWithdrawal(prev => ({ ...prev, amount: parseFloat(data < 0 ? 0 : data) }))
      }
    } catch (error) {
      console.error('Fetch error:', error);
    };
  };

  const setActions = (res) => {
    const { success, data } = res;
    const messageAlert = success ? `The withdrawal has been sent and is being verified by an administrator.` : data
    showAlert(success, messageAlert);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const { amount } = withdrawal;
    if (amount) {
      const response = await fetchWithdrawal(withdrawal);
      setActions(response);
    } else {
      dispatch(setInformativeMessage({ success: false, message: "Amount is required." }));
    };
    setLoading(false);
  };

  useEffect(() => {
    getLimit();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearInformativeMessage());
    };
  }, []);

  return (
    <PageLayout >
      {message && <Message />}
      <div className="authPage">
        <div className="wrapper">
          <Card className="formContainer">
            <SoftTypography mt={2} variant="h2" >
              Withdrawal
            </SoftTypography>
            <form onSubmit={handleSubmit}>
              <SoftBox className="row" m={3}>
                {wallet && <Crypto setWithdrawal={setWithdrawal} withdrawal={withdrawal} limit={limit} />}
                {iban && <Bank setWithdrawal={setWithdrawal} withdrawal={withdrawal} limit={limit} />}
                <Buttons loading={loading} limit={limit} />
              </SoftBox>
            </form>
          </Card>
        </div>
      </div>
    </PageLayout>
  );
};
