import { useState } from 'react';

// Soft UI Dashboard React component
import SoftInput from "components/SoftInput";
import SoftTypography from 'components/SoftTypography';
import InputErrors from 'components/InputErrors';

import './styles.css';

export default function UserInput({ sesion }) {

  const { identifier } = sesion.current;
  const [inputError, setInputError] = useState([]);

  const handleChange = (event) => {
    const { target: { value } } = event;
    sesion.current.identifier = value;
    !value ?
      setInputError(["Email is required."]) :
      setInputError([]);
  };

  return (
    <>
      <SoftTypography>
        Email
      </SoftTypography>
      <SoftInput
        className="user__input"
        autoFocus
        label="Email"
        name="identifier"
        onChange={handleChange}
        placeholder="user@mail.com"
        type="email"
        validations={{ required: true }}
        value={identifier}
      />
      <InputErrors
        className="errorsContainer"
        errors={inputError}
      />
    </>
  );
}
