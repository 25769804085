import { useState } from 'react';

// Soft UI Dashboard React component
import SoftInput from "components/SoftInput";
import SoftTypography from 'components/SoftTypography';
import InputErrors from 'components/InputErrors';

// Utils
import { visibilityIcon } from 'layouts/Professional/AuthPage/utils/Icons/Password';

import './styles.css';

export default function PasswordInput({ data, showPassword, setShowPassword }) {

  const { password } = data.current;
  const [inputError, setInputError] = useState([]);

  const handleChange = (event) => {
    const { target: { value } } = event;
    data.current.password = value;
    !value ?
      setInputError(["Password is required."]) :
      setInputError([]);
  };

  // Show - Hiden password
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <>
      <SoftTypography>
        Password
      </SoftTypography>
      <SoftInput
        className="password__input"
        label="Password"
        name="password"
        autoFocus
        onChange={handleChange}
        type={showPassword ? "text" : "password"}
        validations={{ required: true }}
        value={password}
        icon={visibilityIcon(showPassword, handleClickShowPassword)}
      />
      <InputErrors
        className="errorsContainer"
        errors={inputError}
      />
    </>
  );
}
