// react-redux
import { useSelector } from 'react-redux';

import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import { Chart, PointElement, LineElement } from 'chart.js';
import colors from "assets/theme/base/colors";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Utils
import { getLineChartData } from 'utils/parseShifts';

// Constans
import { IS_CLIENT_BASTION } from 'apiconfig';
import { SYMBOLS } from 'constants';

Chart.register(PointElement, LineElement);

export default function PerformanceDashboard() {

  const { tokens, globalShifts } = useSelector(state => state.charts);
  const { info, warning } = colors;

  return (
    <Card>
      <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" m={1.8}>
        <SoftBox lineHeight={1} >
          <SoftTypography variant="h4">
            <span style={{ color: info.main }}>{IS_CLIENT_BASTION ? SYMBOLS.BC : SYMBOLS.AMV}</span>/
            <span style={{ color: warning.main }}>BTC</span>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <DefaultLineChart chart={getLineChartData(globalShifts, tokens, "BTC")} />
    </Card>
  );
};
