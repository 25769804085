/***** We receive an action and set de data to the store *****/
import { FETCH_USERS, SET_USERS_STATUS, CLEAR_USERS } from "constants/actionTypes";
import { STATUS } from "constants/fetchStatus";

export default (users = { data: [], status: STATUS.PENDING }, action) => {
    switch (action.type) {
        case FETCH_USERS:
            return { ...users, data: action.payload };
        case SET_USERS_STATUS:
            return { ...users, status: action.payload };
        case CLEAR_USERS:
            return { data: [], status: STATUS.PENDING };
        default:
            return users;
    };
};