// KEYS
export const SET_KEYS = "SET_KEYS";
export const SET_KEYS_STATUS = "SET_KEYS_STATUS";
export const UPDATE_KEY_ACTIVE = "UPDATE_KEY_ACTIVE";
export const SET_EXCHANGE_KEYS_STATUS = "SET_EXCHANGE_KEYS_STATUS";
export const ADD_OR_UPDATE_EXCHANGE_KEYS = "ADD_OR_UPDATE_EXCHANGE_KEYS";
export const CLEAR_KEYS = "CLEAR_KEYS";

// ORDERS
export const ADD_ORDERS = "ADD_ORDERS";
export const SET_ORDERS = "SET_ORDERS";
export const LOADING_ORDERS = "LOADING_ORDERS";
export const CLEAR_ORDERS = "CLEAR_ORDERS";

// WALLET
export const SET_WALLET_DATA = "SET_WALLET_DATA";
export const WALLET_STATUS = "WALLET_STATUS";
export const CLEAR_WALLET = "CLEAR_WALLET";

// USERS
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_USERS_STATUS = "SET_USERS_STATUS";
export const FETCH_USERS = "FETCH_USERS";
export const CLEAR_CURRENT_USER = "CLEAR_CURRENT_USER";
export const CLEAR_USERS = "CLEAR_USERS";

// MESSAGES
export const SET_INFORMATIVE_MESSAGE = "SET_INFORMATIVE_MESSAGE";
export const INFORMATIVE_MESSAGE_STATUS = "INFORMATIVE_MESSAGE_STATUS";
export const CLEAR_INFORMATIVE_MESSAGE = "CLEAR_INFORMATIVE_MESSAGE";

// CHARTS
export const SET_TOKENS_DATA = "SET_TOKENS_DATA";
export const SET_GLOBAL_SHIFTS_DATA = "SET_GLOBAL_SHIFTS_DATA";
export const CHARTS_STATUS = "CHARTS_STATUS";
export const CLEAR_CHARTS = "CLEAR_CHARTS";
