// Soft UI Dashboard React components
import SoftButton from "components/SoftButton";

// @mui material components
import { Grid } from "@mui/material";
import DangerousIcon from '@mui/icons-material/Dangerous';

export default function TemplatePanicButton({ action }) {

    return (
        <Grid item xs={8} md={4} lg={4} justifyContent="space-between" sx={{ lineHeight: 0 }}>
            <SoftButton
                variant={"gradient"}
                color="error"
                size="large"
                onClick={action}
            >
                <DangerousIcon /> &nbsp;
                STOP BOT
            </SoftButton>
        </Grid>
    );

}