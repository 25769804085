import {
    SET_CURRENT_USER,
    CLEAR_WALLET,
    CLEAR_CURRENT_USER,
    CLEAR_USERS,
    CLEAR_ORDERS,
    CLEAR_KEYS,
    CLEAR_INFORMATIVE_MESSAGE,
    CLEAR_CHARTS,
} from "constants/actionTypes";
import { getKeys } from "./keys";
import { USER_TYPES } from "constants";

/***** Redux actions that end up in the store *****/
export const setCurrentUser = user => async dispatch => {
    try {
        const { tipo_usuario } = user;
        dispatch({ type: SET_CURRENT_USER, payload: user });
        (tipo_usuario === USER_TYPES.TRADER || tipo_usuario === USER_TYPES.PORTFOLIO) && dispatch(getKeys())
    } catch (error) {
        console.log(error.message)
    };
};

export const logoutSesion = () => async dispatch => {
    dispatch({ type: CLEAR_WALLET, payload: null });
    dispatch({ type: CLEAR_ORDERS, payload: null });
    dispatch({ type: CLEAR_CURRENT_USER, payload: null });
    dispatch({ type: CLEAR_USERS, payload: null });
    dispatch({ type: CLEAR_KEYS, payload: null });
    dispatch({ type: CLEAR_INFORMATIVE_MESSAGE, payload: null });
    dispatch({ type: CLEAR_CHARTS, payload: null });
};