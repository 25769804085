// @mui material components
import { Icon } from "@mui/material";

// Soft UI Dashboard React components
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

export default function ExchangeButton({ handleOpen }) {

    return (
        <SoftButton
            className="button-exchange-keys"
            variant="gradient"
            color="info"
            style={{ padding: "14px 0px" }}
            onClick={handleOpen}
        >
            <SoftTypography
                className="text-exchange-keys"
                mr={1}
                variant="caption"
                color="white"
            >
                {"API Keys"}
            </SoftTypography>
            <Icon>arrow_forward_ios</Icon>
        </SoftButton>
    );
}