import { useState } from 'react';

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// Local components
// import ProfileInformation from "./components/ProfileInformation";
import Header from './components/Header';
import Exchanges from '../Exchanges';
import ProfileInformation from './components/ProfileInformation';

function UserProfile() {

  const [isExchangeOpen, setIsExchangeOpen] = useState(false);

  return (
    <DashboardLayout>
      <Header isExchangeOpen={isExchangeOpen} setIsExchangeOpen={setIsExchangeOpen} />
      {isExchangeOpen ? <Exchanges /> : <ProfileInformation isExchangeOpen={isExchangeOpen} setIsExchangeOpen={setIsExchangeOpen} />}
      <Footer />
    </DashboardLayout>
  );
}

export default UserProfile;
