import { USER_STATUS } from 'constants';
import { ROUTES } from 'constants';
import { store } from 'index';
import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ component }) => {
  const { jwtToken, status } = store.getState().currentUser.data
  return (jwtToken && status === USER_STATUS.ACTIVE) ? component : <Navigate to={ROUTES.LOGIN} replace />;
}

export default PrivateRoute;