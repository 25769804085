import axios from "axios";
import { BACK_API_AMV, IS_CLIENT_BASTION } from "apiconfig";
import { DEPOSIT_TYPE } from "constants";
import { BACK_API } from "apiconfig";

export const fetchRegister = async user => {

    const {
        firstName,
        lastName,
        email,
        password,
        depositType,
        account
    } = user;

    const accountProperty = IS_CLIENT_BASTION ? 'iban' : depositType === DEPOSIT_TYPE.CRYPTO ? 'wallet' : 'iban';

    // Create a new FormData object
    const body = new FormData();
    body.append('name', firstName);
    body.append('surnames', lastName);
    body.append('email', email);
    body.append('password', password);
    body.append(accountProperty, account);

    const url = `${BACK_API_AMV}/register`;
    const headers = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': '*/*',
        }
    };

    return await axios
        .post(url, body, headers)
        .then(({ data }) => {
            return { success: true, data }
        })
        .catch(({ response }) => {
            const typeError = typeof response.data.detail;
            return typeError === 'string' ?
                { success: false, data: response.data.detail } :
                { success: false, data: 'Internal server error' };
        });
};

export const fetchLogin = async sesion => {

    const {
        identifier,
        password,
    } = sesion;

    // Create a new FormData object
    const body = new FormData();
    body.append('email', identifier);
    body.append('password', password);

    const url = `${BACK_API_AMV}/login`;
    const headers = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': '*/*',
        }
    };

    return await axios
        .post(url, body, headers)
        .then(({ data }) => {
            return { success: true, data }
        })
        .catch(({ response }) => {
            return { success: false, data: response.data.detail }
        });
};

export async function fetchForgotPassword(email) {

    const url = `${BACK_API}/forgot-password`;
    const body = {
        email
    };
    return await axios
        .post(url, body)
        .then(() => {
            return {
                success: true,
                message: 'You will receive a password recovery link at your email address in a few minutes.',
            };
        })
        .catch(({ response }) => {
            return { success: false, message: response.data.error };
        });
};

export async function fetchResetPassword(token, newPassword) {

    const url = `${BACK_API}/reset-password`;
    const body = {
        token,
        newPassword
    };
    return await axios
        .post(url, body)
        .then(() => {
            return {
                success: true,
                message: `Your password has been reset.`
            };
        })
        .catch(({ response }) => {
            return { success: false, message: response.data.error };
        });
};
