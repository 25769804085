// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Images
import curved0 from "assets/images/curved-images/curved0.jpg";

// Local components
import Avatar from "./components/Avatar";
import InformationUser from "./components/InformationUser";
import Buttons from "./components/Buttons";

function Header({ isExchangeOpen, setIsExchangeOpen }) {

  return (
    <>
      <SoftBox mt={3} position="relative">
        <SoftBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="15.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${curved0})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            backdropFilter: `saturate(200%) blur(30px)`,
            backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
            boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Avatar />
            <InformationUser />
            <Buttons isExchangeOpen={isExchangeOpen} setIsExchangeOpen={setIsExchangeOpen} />
          </Grid>
        </Card>
      </SoftBox>
    </>
  );
}

export default Header;
