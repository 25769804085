//Animations
import success from "assets/animation/success.json";
import failed from "assets/animation/failed.json";
import Lottie from "lottie-react";

export default function ProfileAnimation({ setIsCompleted, error }) {

    return (
        <center>
            <Lottie
                loop={false}
                style={{ display: "inline-block", padding: "0px !important", margin: "0px !important", width: 54, height: "auto" }}
                onComplete={() => setIsCompleted(false)}
                animationData={error ? failed : success} />
        </center>
    );

}