import { useNavigate } from 'react-router-dom';

// @mui material components
import { Grid } from "@mui/material";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

// Soft UI Dashboard React components
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import { ROUTES } from 'constants';

export default function WithdrawalButton({ }) {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(ROUTES.WITHDRAWAL);
    }

    return (
        <Grid item xs={12} md={4} xl={4} justifyContent="center">
            <SoftButton variant="gradient" color="info" fullWidth onClick={handleClick}>
                <ArrowCircleDownIcon />&nbsp;
                <SoftTypography variant="button" color="white">
                    Withdraw
                </SoftTypography>
            </SoftButton>
        </Grid>
    );

}