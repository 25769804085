// sweetalert2 components
import Swal from "sweetalert2";

// Sweet Alerts page components
import TemplatePanicButton from "./Template";
import { fetchStopBot } from "api/panicbt";

function PanicButton() {
  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, STOP!'
    }).then((result) => {
      if (result.isConfirmed) {
        fetchStopBot()
          .then(({ success }) => {
            success ?
              Swal.fire(
                'Stoped!',
                'Bot has been stoped.',
                'success'
              ) :
              Swal.fire(
                'Error!',
                'Error stopping the bot, please try again.',
                'error'
              )
          })

      }
    })
  };

  return (
    <TemplatePanicButton action={showAlert} />
  );
}

export default PanicButton;
