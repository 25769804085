import { useEffect } from "react"
// @mui material components
import { Grid } from "@mui/material";
import SoftTypography from "components/SoftTypography";

// @mui material components
import SoftBox from "components/SoftBox";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import '../../index.css';

export default function SymbolSelector({ symbols, selectedExchange, selectedSymbol, setSelectedSymbol }) {
    
    useEffect(() => {
        if(symbols) setSelectedSymbol(symbols[0])
    }, [selectedExchange])

    return (
        <Grid item lg={2}>
            <SoftTypography mb={1} variant="h4">
                Symbol
            </SoftTypography>
            <SoftBox>
                {symbols && <Autocomplete
                    disablePortal
                    value={selectedSymbol}
                    onInputChange={(e, val) => {
                        setSelectedSymbol(val);
                    }}
                    options={symbols}
                    renderInput={params => <TextField {...params} />}
                />}
            </SoftBox>
        </Grid>
    );
}
