// @mui material components
import { Grid } from "@mui/material";

// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";

// Material UI
import { Switch, FormControlLabel } from '@mui/material';

export default function BooleanInput({ inputData }) {
    // type, value, profileInfo
    const { name, value, canEdit, propName, updateState } = inputData;

    const handleInputChange = async () => {
        updateState(prevState => {
            return { ...prevState, [propName]: !prevState[propName] }
        });
    }

    return (
        <Grid item xs={12} md={6} xl={4} >
            <SoftTypography variant="button" fontSize="0.75rem" >
                {name}
            </SoftTypography>
            <FormControlLabel style={{ marginLeft: "0px" }} control={
                <Switch
                    label={"name"}
                    name={propName}
                    checked={value}
                    onChange={handleInputChange}
                    validations={{ required: true }}
                    value={value || false}
                    disabled={!canEdit}
                />
            } />
        </Grid>
    );

}