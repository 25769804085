// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// @mui material components
import { Grid, Card } from "@mui/material";

// Local components
import EditTitle from "./components/Title";
import TypeInput from "./components/Input/Type";

// Data
import { profileData } from "../../data/profileData";
import ChangeButton from "./components/Button/Change";
import CancelButton from "./components/Button/Cancel";

export default function EditLayout({ profileInfo, handleSubmit, editLayout, setEditLayout }) {

    return (
        <Grid container spacing={3}>
            <Grid item xl={12}>
                <Card>
                    <SoftBox m={3}>
                        <EditTitle title={'Basic Info'} />
                        <Grid container spacing={{ xs: 1, md: 2, xl: 3 }} mt='-1rem'>
                            {profileData.map(data => {
                                const { id } = data;
                                let component = <TypeInput {...data} key={id} profileInfo={profileInfo} />;
                                return component;
                            })}
                        </Grid>
                        <Grid container spacing={3} mt='-1rem' justifyContent="center" alignItems="center">
                            <CancelButton setEditLayout={setEditLayout} editLayout={editLayout} />
                            <ChangeButton handleSubmit={handleSubmit} />
                        </Grid>
                    </SoftBox>
                </Card>
            </Grid>
        </Grid>
    );

}