import { useRef, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

// Redux
import { useDispatch } from "react-redux";
import { setInformativeMessage } from 'redux/actions/informativeMessage';

// Soft UI Dashboard React component
import PageLayout from "examples/LayoutContainers/PageLayout";

// Local Components
import PasswordInput from "./components/Inputs/Password";
import ConfirmPasswordInput from "./components/Inputs/ConfirmPassword";
import ResetPasswordButton from "./components/Buttons/ResetPassword";
import SignInLink from "./components/Buttons/SignInLink";

// API
import { fetchResetPassword } from 'api/auth';
import { ROUTES } from 'constants';

import './styles.css';

export default function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const data = useRef({
    password: "",
    passwordConfirmation: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Get token
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Get a specific query parameter
  const token = searchParams.get('token');

  const validateInputs = () => {
    const {
      password,
      passwordConfirmation
    } = data.current;

    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;

    if (!password || !passwordConfirmation) {
      return { success: false, message: "Fiels are required." };
    }
    if (password !== passwordConfirmation) {
      return { success: false, message: "Passwords do not match. Please try again." };
    };
    if (!passwordRegex.test(password)) {
      return { success: false, message: "Password must contain at least 8 characters, 1 uppercase, and 1 number" };
    };

    return { success: true, message: "" };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const {
      password,
    } = data.current;

    const validation = validateInputs();

    if (validation.success) {
      const response = await fetchResetPassword(token, password);
      dispatch(setInformativeMessage({ ...response }));
      response.success && navigate(ROUTES.LOGIN)
    } else {
      dispatch(setInformativeMessage({ ...validation }));
    };
    setLoading(false);
  };

  return (
    <PageLayout>
      <div className="authPage">
        <div className="wrapper">
          <div className="formContainer">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <PasswordInput data={data} showPassword={showPassword} setShowPassword={setShowPassword} />
                <ConfirmPasswordInput data={data} showPassword={showPassword} />
                <ResetPasswordButton loading={loading} />
              </div>
            </form>
          </div>
          <SignInLink />
        </div>
      </div>
    </PageLayout>
  );
}
