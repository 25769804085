// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// @mui material components
import { Grid, Card } from "@mui/material";

// Local components
import ExchangesTitle from "./components/Title";
import ExchangesData from "./components/Information";

export default function ExchangesLayout({ isExchangeOpen, setIsExchangeOpen, profileInfo }) {

    return (
        <Grid container spacing={3}>
            <Grid item xl={12}>
                <Card >
                    <SoftBox m={3} height="28rem">
                        <ExchangesTitle isExchangeOpen={isExchangeOpen} setIsExchangeOpen={setIsExchangeOpen} />
                        <ExchangesData profileInfo={profileInfo} isExchangeOpen={isExchangeOpen} setIsExchangeOpen={setIsExchangeOpen} />
                    </SoftBox>
                </Card>
            </Grid>
        </Grid>
    );

}