import * as Yup from "yup";
import checkout from "./form";
import { IS_CLIENT_BASTION } from "apiconfig";

const {
  formField: { firstName, lastName, email, password, depositType, repeatPassword, question1, question2, question3, account },
} = checkout;

const validations = [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg),
    [lastName.name]: Yup.string().required(lastName.errorMsg),
    [email.name]: Yup.string().required(email.errorMsg).matches(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/, email.invalidMsg),
    [password.name]: Yup.string().required(password.errorMsg).min(8, password.invalidMsg),
    [depositType.name]: !IS_CLIENT_BASTION && Yup.string().required(depositType.errorMsg),
    [account.name]: Yup.string().required(account.errorMsg),
    // [repeatPassword.name]: Yup.string()
    //   .required(repeatPassword.errorMsg)
    //   .oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
  }),
  // Yup.object().shape({
  //   [question1.name]: Yup.string().required(question1.errorMsg),
  //   [question2.name]: Yup.string().required(question2.errorMsg),
  //   [question3.name]: Yup.string().required(question3.errorMsg),
  // }),
  // Yup.object().shape({
  //   [account.name]: Yup.string().required(account.errorMsg),
  // }),
];

export default validations;
