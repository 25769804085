import "./styles.scss"

function CryptoPair({ symbol }) {
    const coin1 = symbol.split("/" || "_")[0].toLowerCase();

    return (
        <div className="crypto-pair-container">
            <img style={{ width: "28px", height: "28px" }} src={`https://cdn.jsdelivr.net/npm/cryptocurrency-icons@0.18.1/32/icon/${coin1}.png`} />
            <div className="name">{symbol}</div>
        </div>
    )
};

export default CryptoPair;