// Soft UI Dashboard React components
import SoftAvatar from "components/SoftAvatar";

// @mui material components
import { Grid } from "@mui/material";


export default function Avatar({ }) {

    return (
        <Grid item xs="auto" md={0} xl={0}>
            <SoftAvatar
                src={""}
                alt="profile-image"
                variant="rounded"
                size="xl"
                shadow="sm"
            />
        </Grid>
    );

}