import { useNavigate } from 'react-router-dom';

// Redux
import { useDispatch } from "react-redux";
import { logoutSesion } from 'redux/actions/currentUser';

// @mui material components
import { Grid } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';

// Soft UI Dashboard React components
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';

// Utils
import { ROUTES } from 'constants';

export default function SignoutButton({ }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClick = () => {
        dispatch(logoutSesion());
        sessionStorage.clear();
        localStorage.clear();
        navigate(ROUTES.LOGIN);
    }

    return (
        <Grid item xs={12} md={4} xl={4} justifyContent="center">
            <SoftButton variant="gradient" color="error" fullWidth onClick={handleClick}>
                <LogoutIcon />&nbsp;
                <SoftTypography variant="button" color="white">
                    Sign out
                </SoftTypography>
            </SoftButton>
        </Grid>
    );

};