// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";

// @mui material components
import { Grid } from "@mui/material";

export default function EditTitle({ title }) {

    return (
        <Grid container spacing={3} direction="row" >
            <Grid item xs={10} >
                <SoftTypography variant="h4" sx={{ display: "inline-block" }}>
                    {title}
                </SoftTypography>
            </Grid>
        </Grid>
    );

}