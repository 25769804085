import checkout from "./form";

const {
  formField: {
    firstName,
    lastName,
    email,
    password,
    repeatPassword,
    question1,
    question2,
    question3,
    depositType,
    account,
  },
} = checkout;

const initialValues = {
  [firstName.name]: "",
  [lastName.name]: "",
  [email.name]: "",
  [password.name]: "",
  [repeatPassword.name]: "",
  [question1.name]: "",
  [question2.name]: "",
  [question3.name]: "",
  [depositType.name]: "",
  [account.name]: "",
};

export default initialValues;
