// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import { useSelector, useDispatch } from "react-redux"
import ExchangeInput from "../ExchangeInput";
import ExchangeKeyButtons from "../ExchangeKeyButtons";
import { STATUS } from "constants/fetchStatus";
import { saveKeys } from "redux/actions/keys";
import "../../index.css";


export default function KeyManager({ exchange }) {

    const { label, referalLink, publicKey, privateKey, password, setPublicKey, setPrivateKey, setPassword, value, status, needsPassword } = exchange
    const dispatch = useDispatch()
    const handleChangePublicKey = e => setPublicKey(e.target.value.trim())
    const handleChangePrivateKey = e => setPrivateKey(e.target.value.trim())
    const handleChangePassword = e => setPassword(e.target.value.trim())

    const currentUser = useSelector(state => state.currentUser.data)

    const handleSubmit = () => {
        if (privateKey && publicKey) {
            dispatch(saveKeys(currentUser.email, privateKey, publicKey, password, value))
        } else {
            alert("You need to add keys")
        }
    }

    return (
        <SoftBox>
            <Grid p={3} container spacing={3}>
                <ExchangeInput
                    name="Public Key"
                    value={publicKey}
                    handle={handleChangePublicKey}
                    type="text"
                />
                <ExchangeInput
                    name="Private Key"
                    value={privateKey}
                    handle={handleChangePrivateKey}
                    type="password"
                />
                {
                    needsPassword &&
                    <ExchangeInput
                        name="Password"
                        value={password}
                        handle={handleChangePassword}
                        type="password"
                    />
                }
            </Grid>
            {status !== STATUS.LOADING &&
                <ExchangeKeyButtons referalLink={referalLink} label={label} handleSubmit={handleSubmit} />
            }
        </SoftBox>
    );
}
