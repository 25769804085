// @mui material components
import {
    FormControl,
    FormGroup,
    FormControlLabel,
    Switch
} from "@mui/material";

export default function ExchangeSwitch({ activeLoading, active, submitActive }) {

    return (
        <FormControl component="fieldset" variant="standard">
            <FormGroup>
                <FormControlLabel
                    control={
                        <Switch disabled={activeLoading} checked={active} onChange={submitActive} />
                    }
                    label={active ? "Enabled" : "Disabled"}
                />
            </FormGroup>
        </FormControl>
    );
}