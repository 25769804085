// Soft UI Dashboard React components
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import TradesTable from "./components/TradesTable/index";
import { getOrders } from "redux/actions/orders";
import Loader from "components/Loader";
import RefreshButton from "./components/RefreshButton/index";
import WalletsAddedGuard from "componentsCustom/WalletsAddedGuard";
import './index.css';

function Orders() {

  const dispatch = useDispatch()
  const trades = useSelector(state => state.orders.data.trades)
  const tradesLoading = useSelector(state => state.orders.loading)
  const [selectedOrderType, setSelectedOrderType] = useState("closed")
  const selectedTrades = trades.filter(trade => trade.status === selectedOrderType)
  const openTradesLength = trades.filter(trade => trade.status === "open").length

  const getInitialTrades = () => {
    dispatch(getOrders())
  }

  const handleOrderType = e => {
    setSelectedOrderType(e.target.value)
  }
  const tradesSelected = selectedOrderType === "open" || selectedOrderType === "closed"

  return (
    <DashboardLayout>
      <WalletsAddedGuard>
        <RefreshButton isLoading={tradesLoading} action={getInitialTrades} />
        <SoftButton key="closed" className={selectedOrderType === "closed" ? "selected" : "notSelected"} variant="text" color="dark" value="closed" onClick={handleOrderType}>Closed</SoftButton>
        <SoftButton key="open" className={selectedOrderType === "open" ? "selected" : "notSelected"} variant="text" color="dark" value="open" onClick={handleOrderType}>{`Open (${openTradesLength})`}</SoftButton>
        {tradesLoading && <Loader />}
        {tradesSelected && <TradesTable trades={selectedTrades} getInitialTrades={getInitialTrades} selectedOrderType={selectedOrderType} />}
      </WalletsAddedGuard>
    </DashboardLayout>
  );
}

export default Orders;