/***** We receive an action and set de data to the store *****/
import { LOADING_ORDERS, ADD_ORDERS, SET_ORDERS, CLEAR_ORDERS } from "constants/actionTypes"
import sortOrdersByTimestamp from "utils/sortOrdersByTimestamp"

const initialOrdersData = { trades: [] }

export default (orders = { data: initialOrdersData, loading: false }, action) => {
    switch (action.type) {
        case SET_ORDERS:
            const { orders: fetchedOrders } = action.payload
            return { ...orders, data: { trades: sortOrdersByTimestamp(fetchedOrders) } }
        case ADD_ORDERS:
            const updatedOrders = orders?.data.filter(
                oldOrder => !action.payload.some(newOrder => newOrder.id === oldOrder.id)
            );
            const sortedOrders = sortOrdersByTimestamp([...updatedOrders, ...action.payload])
            return { ...orders, data: sortedOrders };
        case LOADING_ORDERS:
            return { ...orders, loading: action.payload }
        case CLEAR_ORDERS:
            return { data: initialOrdersData, loading: false };
        default:
            return orders
    }
}