import DataTable from "examples/Tables/DataTable";
import EditUserButton from './EditUserButton';
import SoftBadge from "components/SoftBadge";
import { STATUS } from 'constants/fetchStatus';
import { USER_STATUS } from "constants";
import Loader from 'components/Loader';
import { timestampToDateTime } from "utils/timestampToDateTime";
import { IS_CLIENT_BASTION } from "apiconfig";

const getAMVStatusByValue = status => {
    switch (status) {
        case 0:
            return "0. Registered"
        case 1:
            return "1. KYC approved"
        case 2:
            return "2. Deposit to be approved"
        case 3:
            return "3. Active"
    }
}

const getBastionStatusByValue = status => {
    switch (status) {
        case 1:
            return "1. Registered"
        case 2:
            return "2. Deposit to be approved"
        case 3:
            return "3. Active"
    }
}

const parseRegisterDate = registerDate => {
    const timestamp = Date.parse(new Date(registerDate))
    const parsedDate = timestampToDateTime(timestamp)
    return { timestamp, parsedDate }
}

// Badges
const active = (
    <SoftBadge variant="contained" color="secondary" size="xs" badgeContent="Active" container />
);

const getInactiveBadge = value => {
    return <SoftBadge variant="gradient" color="info" size="xs" badgeContent={IS_CLIENT_BASTION ? getBastionStatusByValue(value) : getAMVStatusByValue(value)} container />
}
const getNoTransactionsBadge = value => {
    return <SoftBadge variant="contained" color="secondary" size="xs" badgeContent={`${value}`} container />
}
const getTransactionsBadge = value => {
    return <SoftBadge variant="gradient" color="info" size="xs" badgeContent={value} container />
}

const countPendingTransactions = (deposits, withdrawals) => {
    const pendingDepositsCount = deposits.filter(deposit => deposit.approved === null).length
    const pendingWithdrawalsCount = withdrawals.filter(withdrawal => withdrawal.approved === null).length
    return pendingDepositsCount + pendingWithdrawalsCount
}


export default function UsersTable({ users, editClickedUser }) {
    const columns = [
        { Header: "Edit", accessor: "edit", width: "9%" },
        { Header: "email", accessor: "email" },
        {
            Header: "Transactions requested",
            accessor: "pendingTransactions",
            Cell: ({ value }) => value > 0 ? getTransactionsBadge(value) : getNoTransactionsBadge(value)
        },
        {
            Header: "User status",
            accessor: "userstatus",
            Cell: ({ value }) => value === USER_STATUS.ACTIVE ? active : getInactiveBadge(value)
        },
        { Header: "Name", accessor: "name" },
        {
            Header: "Register date",
            accessor: "registerDate.timestamp",
            Cell: ({ row }) => row.original.registerDate.parsedDate
        }
    ];
    const entriesPerPage = {
        defaultValue: 10,
        entries: [5, 10, 15, 50, 100]
    }

    const tableData = getTableContent(users.data)

    function getTableContent(users) {
        const rows = users.map(user => {
            const {
                email,
                deposits,
                withdrawals,
                name,
                surnames,
                status,
                registerDate
            } = user;
            const parsedRegisterDate = parseRegisterDate(registerDate)
            return {
                edit: <EditUserButton editClickedUser={editClickedUser} user={user} />,
                email,
                pendingTransactions: countPendingTransactions(deposits, withdrawals),
                userstatus: status,
                name: `${name} ${surnames}`,
                registerDate: parsedRegisterDate
            };
        });
        return { columns, rows }
    }
    return (
        <>
            {users.status === STATUS.LOADING && <Loader />}
            {users.status === STATUS.ERROR && <h1>Error</h1>}
            {(users.status === STATUS.OK || users.status === STATUS.LOADING) &&
                <DataTable
                    entriesPerPage={entriesPerPage}
                    showTotalEntries={true}
                    isSorted={true}
                    canSearch={true}
                    table={tableData}
                    defaultSortBy={"registerDate.timestamp"} />
            }
        </>
    )
}