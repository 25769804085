// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// @mui material components
import { Grid, Card } from "@mui/material";

// Local components
import InformationTitle from "./components/Title";
import InformationData from "./components/Information";

export default function InformationLayout({ editLayout, setEditLayout, profileInfo }) {

    return (
        <Grid container spacing={3}>
            <Grid item xl={12}>
                <Card>
                    <SoftBox m={3} height="28rem">
                        <InformationTitle editLayout={editLayout} setEditLayout={setEditLayout} />
                        <InformationData profileInfo={profileInfo} />
                    </SoftBox>
                </Card>
            </Grid>
        </Grid>
    );

}