/***** We receive an action and set de data to the store *****/
import { SET_CURRENT_USER, CLEAR_CURRENT_USER } from "constants/actionTypes";

export default (currentUser = { data: [], loading: false }, action) => {
    switch (action.type) {
        case SET_CURRENT_USER:
            return { data: action.payload };
        case CLEAR_CURRENT_USER:
            return { data: [], loading: false };
        default:
            return currentUser;
    };
};