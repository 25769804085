import { Link } from 'react-router-dom';

import './styles.css';
import { ROUTES } from 'constants';

export default function SignInLink() {

  return (
    <div className="linkContainer">
      <div>
        Already have an account? {" "}
        <Link to={ROUTES.LOGIN}>Sign in</Link>
      </div>
    </div>
  );
}
