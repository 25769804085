import { DotPulse } from '@uiball/loaders'

export default function Loader() {
    return (
        <div style={{ display: "flex", justifyContent: "center", margin: "24px" }}>
            <DotPulse
                size={60}
                speed={0.9}
                color="#00ACC1"
            />
        </div>
    )
}