import axios from "axios"
import { BACK_API } from "apiconfig"
import { store } from "index";

export async function fetchOrders(email, exchanges) {
    const headers = { 'token': store.getState().currentUser.data.jwtToken };
    // By email with every active exchange
    const url = `${BACK_API}/orders`
    const paramsList = exchanges.map(exchange => {
        const exchangeNeededData = {
            exchange: exchange,
            email: email
        }
        return exchangeNeededData
    })

    const requests = paramsList.map(body => axios.post(url, body, { headers }))
    const orders = []
    await Promise.all(requests)
        .then(responses => {
            responses.forEach(res => {
                res.data.rows.map(row => {
                    const result = {
                        id: row.id_order,
                        symbol: row.ordersymbol,
                        side: row.orderside,
                        type: row.ordertype,
                        amount: row.orderamount,
                        price: row.orderprice,
                        timestamp: row.orderdate,
                        status: row.orderstatus,
                        exchange: row.exchange
                    }
                    orders.push(result)
                });
            });
        })
        .catch(error => {
            console.error(error);
        });
    return orders
}

export async function fetchSuccessfulArbitrages(email) {
    const headers = { 'token': store.getState().currentUser.data.jwtToken };
    const url = `${BACK_API}/successful-arbitrages`;
    return await axios
        .post(url, { email }, { headers })
        .then(response => response.data.rows)
        .catch(err => console.log(err))
}

export async function cancelOrder(order) {
    const keys = store.getState().keys.data
    const selectedExchageKeys = keys.find(exchange => exchange.exchange === order.exchange)
    const headers = { 'token': store.getState().currentUser.data.jwtToken };
    const url = `${BACK_API}/cancel-order`
    try {
        await axios
            .post(url, {
                exchange: order.exchange.toLowerCase(),
                public_key: selectedExchageKeys.publicKey,
                private_key: selectedExchageKeys.privateKey,
                password: selectedExchageKeys.password,
                order_id: order.id,
                symbol: order.symbol
            }, { headers })
    } catch (error) {
        console.log(error)
        throw Error(error)
    }
}