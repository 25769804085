import SoftTypography from "components/SoftTypography";

export default function Side({ side }) {
    return (side === "buy" ? (
        <SoftTypography className="crypto-buy-sell" color={"success"}>
            Buy
        </SoftTypography>) : (
        <SoftTypography className="crypto-buy-sell" color={"error"}>
            Sell
        </SoftTypography>)
    )
}