import { useNavigate } from 'react-router-dom';

// @mui material components
import { Grid } from "@mui/material";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

// Soft UI Dashboard React components
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import { ROUTES } from 'constants';

export default function DepositButton({ }) {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(ROUTES.DEPOSIT, {
            state: {
                isProfile: true,
            }
        });
    }

    return (
        <Grid item xs={12} md={4} xl={4} justifyContent="center">
            <SoftButton variant="gradient" color="info" fullWidth onClick={handleClick}>
                <ArrowCircleUpIcon />&nbsp;
                <SoftTypography variant="button" color="white">
                    Deposit
                </SoftTypography>
            </SoftButton>
        </Grid>
    );

}