import { useNavigate, useLocation } from 'react-router-dom';

// Redux
import { useDispatch } from "react-redux";
import { logoutSesion } from 'redux/actions/currentUser';

// Soft UI Dashboard React component
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

import { ROUTES } from 'constants';

import './styles.css';

export default function CancelButton({ loading }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (event) => {
    event.preventDefault();
    if (location.state?.isProfile) {
      navigate(ROUTES.PROFILE);
    } else {
      dispatch(logoutSesion());
      navigate(ROUTES.LOGIN);
    }
  }

  return (
    <div className="buttonContainer">
      <SoftButton
        variant="gradient"
        color="error"
        fullWidth
        onClick={handleClick}
        disabled={loading}
      >
        <SoftTypography
          variant="button"
          color="white"
        >
          Cancel
        </SoftTypography>
      </SoftButton>
    </div>
  );
}
