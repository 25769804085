import { useState } from 'react';

// Redux
import { useSelector, useDispatch } from "react-redux";
import { clearInformativeMessage } from 'redux/actions/informativeMessage';

// @mui material components
import { Snackbar, Alert } from "@mui/material";

import { STATUS } from 'constants/fetchStatus';

import './styles.css';

export default function Message() {

    const informativeMessage = useSelector(state => state.informativeMessage);
    const dispatch = useDispatch();
    const { message, status } = informativeMessage;

    const [position, setPosition] = useState({
        open: true,
        vertical: 'top',
        horizontal: 'center',
    });

    const { vertical, horizontal, open } = position;

    const handleClose = () => {
        setPosition(prev => ({ ...prev, open: false }));
        dispatch(clearInformativeMessage());
    };

    return (
        <Snackbar
            className="conatiner"
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            autoHideDuration={6000}
        >
            <Alert
                onClose={handleClose}
                severity={status === STATUS.ERROR ? "error" : "success"}
            >
                {message}
            </Alert>
        </Snackbar>
    );
};
