import { useState } from 'react';

// Redux
import { useSelector } from "react-redux";

// Soft UI Dashboard React component
import SoftInput from "components/SoftInput";
import SoftTypography from 'components/SoftTypography';

// @mui material components
import { OutlinedInput, InputBase } from '@mui/material';

import './styles.css';

export default function AmountInput({ setWithdrawal, withdrawal, maxAmount }) {
  const { amount } = withdrawal;
  const { wallet } = useSelector(state => state.currentUser.data);
  const [inputError, setInputError] = useState(false);

  const handleChange = ({ target }) => {
    const { value } = target;
    setWithdrawal(prev => ({ ...prev, amount: value <= 0 ? 0 : parseFloat(value > maxAmount ? maxAmount : value) }));
    if (value < 0) {
      setInputError(true);
    } else {
      setInputError(false);
    };
  };

  const getInputProps = () => {
    return maxAmount ? {
      type: "number",
      startAdornment: (
        <SoftTypography className="typography" variant="button">
          {wallet ? "USDT" : "EUR"}
        </SoftTypography>
      ),
      inputComponent: InputBase,
      inputProps: {
        sx: {
          ml: '2% !important',
          width: '100% !important',
        }
      }
    } : {

    };
  };

  return (
    <>
      <SoftInput
        className="amount__input"
        name="amount"
        label="Amount"
        placeholder="Amount"
        value={amount || ""}
        validations={{ required: true }}
        error={inputError}
        onChange={handleChange}
        inputComponent={maxAmount ? OutlinedInput : null}
        inputProps={getInputProps()}
        disabled={!maxAmount}
        required

      />
    </>
  );
}
