import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

// react-redux
import { useSelector, useDispatch } from 'react-redux';
import { setInformativeMessage } from 'redux/actions/informativeMessage';
import { setCurrentUser, logoutSesion } from 'redux/actions/currentUser';
import { getUsers } from 'redux/actions/users';
import { getTokensBalances } from 'redux/actions/charts';

// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftAvatar from "components/SoftAvatar";

// Authentication layout components
import BasicLayout from "./components/BasicLayout";

// Utils
import { visibilityIcon } from 'layouts/Professional/AuthPage/utils/Icons/Password';
import { USER_TYPES, ROUTES } from 'constants';

// API
import { fetchLogin } from 'api/auth';

function Lock() {
  const { name, surname, email } = useSelector(state => state.currentUser.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [inputError, setInputError] = useState(false);

  const dispatchLoginActions = async (user) => {
    const { tipo_usuario } = user;
    dispatch(setCurrentUser(user));
    tipo_usuario === USER_TYPES.ADMIN && dispatch(getUsers());
    tipo_usuario === USER_TYPES.CLIENT && dispatch(getTokensBalances());
  }

  const handleChange = (event) => {
    const { target: { value } } = event;
    setPassword(value);

    if (value.length < 8) {
      setInputError(true);
    } else {
      setInputError(false);
    }
  };

  // Show - Hiden password
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (password) {
      const sesion = {
        identifier: email,
        password,
      }
      const response = await fetchLogin(sesion);
      const { success, data } = response;
      if (success) {
        const { access_token, user } = data;
        const { user_id, status, email, name, surnames, type, wallet, iban } = user;
        const parsedUser = { jwtToken: access_token, id: user_id, email, name, surname: surnames, tipo_usuario: type, wallet, iban, status };
        dispatchLoginActions(parsedUser);
        navigate(ROUTES.HOME);
      } else {
        dispatch(setInformativeMessage({ success, message: data }));
      }
    } else {
      dispatch(setInformativeMessage({ success: false, message: "Password is required." }));
    }
    setLoading(false);
  };

  const handleClick = () => {
    dispatch(logoutSesion());
    sessionStorage.clear();
    localStorage.clear();
  };

  return (
    <BasicLayout >
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Card>
            <SoftBox py={4} px={3} textAlign="center">
              <SoftBox display="flex" justifyContent="center" mb={3}>
                <SoftAvatar src={""} alt="profile-picture" size="xxl" variant="rounded" />
              </SoftBox>
              <SoftTypography variant="h4" fontWeight="bold">
                {`${name} ${surname}`}
              </SoftTypography>
              <SoftBox mb={3}>
                <SoftTypography variant="body2" color="text">
                  Your session has expired, please enter your account password.
                </SoftTypography>
              </SoftBox>
              <SoftBox component="form" role="form" onSubmit={handleSubmit}>
                <SoftBox mb={2}>
                  <SoftInput
                    placeholder="Password"
                    onChange={handleChange}
                    type={showPassword ? "text" : "password"}
                    value={password}
                    icon={visibilityIcon(showPassword, handleClickShowPassword)}
                    error={inputError}
                    success={!!password && !inputError}
                  />
                </SoftBox>
                <SoftBox mt={4}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    size="large"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? <CircularProgress color="inherit" size={15} /> : "Sign in"}
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        </Grid>
        <Grid item >
          <SoftTypography variant="caption" sx={{ fontSize: "14px" }}>
            Is this your account? {" "}
            <Link to={ROUTES.LOGIN} onClick={handleClick}>Change User</Link>
          </SoftTypography>
        </Grid>
      </Grid>
    </BasicLayout>
  );
}

export default Lock;
