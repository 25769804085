import axios from "axios";
import { BACK_API, BACK_API_AMV } from "apiconfig";
import { store } from "index";

export const fetchDeposit = async depositData => {

    const {
        amount
    } = depositData;

    // Create a new FormData object
    const body = new FormData();
    body.append('total_amount', amount);

    const url = `${BACK_API_AMV}/deposit`;
    const headers = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': '*/*',
            'Authorization': `Bearer ${store.getState().currentUser.data.jwtToken}`,
        }
    };

    return await axios
        .post(url, body, headers)
        .then(({ data }) => {
            return { success: true, data }
        })
        .catch(({ response }) => {
            return { success: false, data: response.data.detail }
        });
};

export const fetchWithdrawal = async withdrawalData => {

    const {
        amount
    } = withdrawalData;

    // Create a new FormData object
    const body = new FormData();
    body.append('total_amount', amount);

    const url = `${BACK_API_AMV}/withdrawal`;
    const headers = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': '*/*',
            'Authorization': `Bearer ${store.getState().currentUser.data.jwtToken}`,
        }
    };

    return await axios
        .post(url, body, headers)
        .then(({ data }) => {
            return { success: true, data }
        })
        .catch(({ response }) => {
            return { success: false, data: response.data.detail }
        });
};

export const fetchWithdrawalLimit = async () => {

    const url = `${BACK_API_AMV}/withdrawal/limit`;
    const headers = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': '*/*',
            'Authorization': `Bearer ${store.getState().currentUser.data.jwtToken}`,
        }
    };

    return await axios
        .get(url, headers)
        .then(({ data }) => {
            return { success: true, data }
        })
        .catch(({ response }) => {
            return { success: false, data: response.data.detail }
        });
};

export async function fetchUserTransactions(userId) {
    const url = `${BACK_API}/user-transactions`;
    const params = { userId: userId };
    const headers = { 'token': store.getState().currentUser.data.jwtToken };
    return await axios
        .get(url, { params, headers })
        .then(res => res.data)
        .catch(err => { throw err })
}


export async function updateTransaction(body) {
    const url = `${BACK_API}/transaction`;
    const headers = { 'token': store.getState().currentUser.data.jwtToken };
    return await axios
        .post(url, body, { headers })
        .then(res => res.data)
        .catch(err => { throw err })
}