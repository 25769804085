import BooleanInput from "./BooleanInput"
import SelectInput from "./SelectInput"
import TextInput from "./TextInput"

export default function Input({ inputData }) {
    const getInputType = () => {
        switch (inputData.inputType) {
            case "boolean":
                return <BooleanInput inputData={inputData} />
            case "select":
                return <SelectInput inputData={inputData} />
            case "text":
                return <TextInput inputData={inputData} />
            case "date":
                return <TextInput inputData={inputData} />
            case "email":
                return <TextInput inputData={inputData} />
            case "number":
                return <TextInput inputData={inputData} />
        }
    }
    return (
        getInputType()
    )
}