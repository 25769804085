// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";

// @mui material components
import { Grid, Button } from "@mui/material";

// @mui icons
import EditIcon from '@mui/icons-material/Edit';;

export default function ExchangesTitle({ isExchangeOpen, setIsExchangeOpen }) {

    const handleClick = () => {
        setIsExchangeOpen(!isExchangeOpen)
    }

    return (
        <Grid container>
            <Grid item xs={11} >
                <SoftTypography variant="h4" sx={{ display: "inline-block" }}>
                    Exchanges
                </SoftTypography>
            </Grid>
            <Grid item xs={1} >
                <Button onClick={handleClick}>
                    <EditIcon />
                </Button>
            </Grid>
        </Grid>
    );

}