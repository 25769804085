// react-redux
import { useSelector } from 'react-redux';

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import PanicButton from "./components/Button/Panic";
import Loader from 'components/Loader';
import PerformanceDashboard from '../components/Charts/PerformanceDashboard';

// Data
import { USER_TYPES } from "constants";
import { STATUS } from 'constants/fetchStatus';

export default function TraderDashboard() {
    const currentUser = useSelector(state => state.currentUser.data);
    const { tipo_usuario } = currentUser;
    const { status } = useSelector(state => state.charts);
    return (
        <DashboardLayout>
            <SoftBox my={3}>
                <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={4} md={8} lg={8} sx={{ lineHeight: 0 }}>
                            <SoftTypography variant="h5">Charts</SoftTypography>
                        </Grid>
                        {tipo_usuario === USER_TYPES.TRADER && <PanicButton />}
                    </Grid>
                </SoftBox>
                <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            {status === STATUS.LOADING && <Loader />}
                            {status === STATUS.OK && <PerformanceDashboard />}
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>
        </DashboardLayout>
    );
}




