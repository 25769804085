/***** We receive an action and set de data to the store *****/
import { SET_TOKENS_DATA, CHARTS_STATUS, CLEAR_CHARTS, SET_GLOBAL_SHIFTS_DATA } from "constants/actionTypes";
import { STATUS } from "constants/fetchStatus";

export default (charts = { tokens: [], globalShifts: [], status: STATUS.PENDING }, action) => {
    switch (action.type) {
        case SET_TOKENS_DATA:
            return { ...charts, tokens: action.payload };
        case SET_GLOBAL_SHIFTS_DATA:
            return { ...charts, globalShifts: action.payload };
        case CHARTS_STATUS:
            return { ...charts, status: action.payload };
        case CLEAR_CHARTS:
            return { tokens: [], globalShifts: [], status: STATUS.PENDING };
        default:
            return charts;
    };
};