import { useSelector } from "react-redux"

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// @mui material components
import { Grid } from "@mui/material";

export default function InformationUser({ }) {

    const { name, surname, email } = useSelector(state => state.currentUser.data);
    const fullName = `${name} ${surname}`

    return (
        <Grid item xs={6} md={4} xl={4}>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>
                <SoftTypography variant="h5" fontWeight="medium">
                    {fullName}
                </SoftTypography>
                <SoftTypography variant="caption" fontWeight="medium">
                    {email}
                </SoftTypography>
            </SoftBox>
        </Grid>
    );

}