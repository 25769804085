import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from "react-redux";
import { setInformativeMessage } from 'redux/actions/informativeMessage';
import { clearInformativeMessage } from 'redux/actions/informativeMessage';

// @mui material components
import { Card } from "@mui/material";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Local components
import Bank from './components/Bank';
import Crypto from './components/Crypto';
import Buttons from './components/Buttons';
import Message from '../Status/Message';

// API
import { fetchDeposit } from 'api/transaction';
import { ROUTES } from 'constants';

// sweetalert2 components
import Swal from "sweetalert2";

export default function Deposit() {

  const currentUser = useSelector(state => state.currentUser.data);
  const { message } = useSelector(state => state.informativeMessage);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { pathname } = location;
  const { iban, wallet } = currentUser;
  const [deposit, setDeposit] = useState({ type: iban || wallet, amount: 0.0 });

  const showAlert = (success, text) => {
    Swal.fire({
      icon: success ? "success" : "error",
      title: success ? "Done" : "Sorry",
      text,
    }).then(() => {
      if (pathname === ROUTES.LOGIN) {
        success && navigate(ROUTES.LOGIN);
      } else {
        success && navigate(ROUTES.PROFILE);
      };
    });
  };

  const setActions = (res) => {
    const { success, data } = res;
    const messageAlert = success ? `The deposit has been sent and is being verified by an administrator.` : data
    showAlert(success, messageAlert);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const { amount } = deposit;
    if (amount) {
      const response = await fetchDeposit(deposit);
      setActions(response);
    } else {
      dispatch(setInformativeMessage({ success: false, message: "Amount is required." }));
    };
    setLoading(false);
  };

  useEffect(() => {
    return () => {
      dispatch(clearInformativeMessage());
    };
  }, [])

  return (
    <PageLayout>
      {message && <Message />}
      <div className="authPage">
        <div className="wrapper">
          <Card className="formContainer">
            <SoftTypography mt={2} variant="h2" >
              Deposit
            </SoftTypography>
            <form onSubmit={handleSubmit}>
              <SoftBox className="row" m={3}>
                {wallet && <Crypto deposit={deposit} setDeposit={setDeposit} />}
                {iban && <Bank deposit={deposit} setDeposit={setDeposit} />}
                <Buttons loading={loading} />
              </SoftBox>
            </form>
          </Card>
        </div>
      </div>
    </PageLayout>
  );
};
