import { useState } from "react"
// @mui material components
import { Grid } from "@mui/material";
import Input from "componentsCustom/Inputs"
import parseDate from "utils/parseDate";
import { updateTransaction } from "api/transaction";
import ChangeButton from "../Button/UpdateTransaction";
import { useDispatch } from "react-redux"
import { getUsers } from "redux/actions/users";
import { STATUS } from "constants/fetchStatus";
import StatusAnimations from "componentsCustom/StatusAnimations";

export default function Transaction({ transaction }) {

    const dispatch = useDispatch()
    const [transactionState, setTransactionState] = useState(transaction)
    const [status, setStatus] = useState(STATUS.PENDING)
    const getUsetTransactionProperties = transaction => {
        return {
            id: {
                name: "Id",
                value: transaction.id,
                inputType: "number",
                canEdit: false
            },
            transactionType: {
                name: "Transaction type",
                value: transaction.transactionType,
                inputType: "text",
                canEdit: false
            },
            approvedDate: {
                name: "Approval Date",
                value: parseDate(transaction.approvedDate),
                inputType: "date",
                canEdit: false
            },
            approved: {
                name: "Approved",
                value: transaction.approved,
                inputType: "select",
                options: [
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                    { label: "Pending", value: null }
                ],
                canEdit: true
            },
            timestamp: {
                name: "Date requested",
                value: parseDate(transaction.timestamp),
                inputType: "date",
                canEdit: true
            },
            netAmount: {
                name: "Amount",
                value: transaction.netAmount,
                inputType: "number",
                canEdit: true
            },
            type: {
                name: "Type",
                value: transaction.type,
                inputType: "text",
                canEdit: true
            },
        }
    }

    const handleSubmit = async () => {
        setStatus(STATUS.LOADING)
        updateTransaction(transactionState)
            .then(res => {
                setTransactionState(prevState => ({...prevState, approvedDate: parseDate(res.approved_date)}))
                dispatch(getUsers())
                setStatus(STATUS.OK)
            })
            .catch(() => {
                setStatus(STATUS.ERROR)
            })
    }

    return <>
        <Grid item md={12}>
            <hr />
        </Grid >
        {
            Object.entries(getUsetTransactionProperties(transactionState)).map((property, i) => {
                const [propName, propData] = property
                return <Input key={i} inputData={{ ...propData, propName, updateState: setTransactionState }} />
            })
        }
        <Grid mb={3} container spacing={3} justifyContent="center" alignItems="center">
            <StatusAnimations status={status} setStatusFunction={setStatus} afterOKAnimationAction={() => { }} />
            {status === STATUS.PENDING && <ChangeButton handleSubmit={handleSubmit} />}
        </Grid>
    </>
}