// Soft UI Dashboard React component
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

import CircularProgress from '@mui/material/CircularProgress';

import './styles.css';


export default function SignInButton({ loading }) {

  return (
    <div className="buttonContainer">
      <SoftButton
        style={{ marginTop: "20px" }}
        variant="gradient"
        color="info"
        fullWidth
        type="submit"
        disabled={loading}
      >
        <SoftTypography
          variant="button"
          color="white"
        >
          {loading ? <CircularProgress color="inherit" size={15} /> : "Sign in"}
        </SoftTypography>
      </SoftButton>
    </div>
  );
}
