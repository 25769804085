// Soft UI Dashboard React components
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import Icon from "@mui/material/Icon";
import '../../index.css';

export default function RefreshButton({ isLoading, action }) {
    
    return (
        <SoftButton disabled={isLoading} className="refresh-button" onClick={action}>
            <SoftTypography
                component="span"
                variant="h2"
                className="refresh-icon"
            >
                <Icon>cached</Icon>
            </SoftTypography>
        </SoftButton>
    )
}