import axios from "axios";
import { BACK_API } from "apiconfig";
import { store } from "index";

export async function fetchUsers() {
  const url = `${BACK_API}/users`;
  const params = {};
  const headers = { 'token': store.getState().currentUser.data.jwtToken };
  return await axios
    .get(url, { params, headers })
    .then(res => res.data);
}

export async function updateUser(body) {
  const parsedBody = { ...body, wallet: body.wallet || null, iban: body.iban || null };
  const url = `${BACK_API}/user`;
  const headers = { 'token': store.getState().currentUser.data.jwtToken };
  return await axios
    .post(url, parsedBody, { headers })
    .then(res => res.data.rows);
}

export async function deleteUser(userId) {
  const url = `${BACK_API}/delete-user`;
  const headers = { 'token': store.getState().currentUser.data.jwtToken };
  const body = { userId }
  return await axios
    .post(url, body, { headers })
    .then(res => res.data);
}