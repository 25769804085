import success from "assets/animation/success.json";
import failed from "assets/animation/failed.json"
import { STATUS } from "constants/fetchStatus";
import Loader from "components/Loader";
import Lottie from "lottie-react";
import React, { useEffect, useRef, useState } from 'react';

export default function ReduxStatusAnimations({ status }) {

    const [isInitialValue, setIsInitialValue] = useState(true)
    const initialRef = useRef(status);

    useEffect(() => {
        if (initialRef.current !== status) {
            setIsInitialValue(false)
            initialRef.current = status
        }
    }, [status]);

    const animationCompleted = () => {
        setIsInitialValue(true)
        initialRef.current = status
    }

    const getAnimationDataByStatus = () => {
        switch (status) {
            case STATUS.OK:
                return success
            case STATUS.ERROR:
                return failed
        }
    }

    return (
        <>
            {status === STATUS.LOADING && <Loader />}
            {(!isInitialValue && (status === STATUS.OK || status === STATUS.ERROR)) && (
                <center>
                    <Lottie
                        loop={false}
                        style={{ display: "inline-block", padding: "0px !important", margin: "0px !important", width: 54, height: "auto" }}
                        onComplete={animationCompleted}
                        animationData={getAnimationDataByStatus()}
                    />
                </center>
            )}
        </>
    )
}