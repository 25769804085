import { useRef, useState } from 'react';

// Redux
import { useDispatch } from "react-redux";
import { setInformativeMessage } from 'redux/actions/informativeMessage';

// Soft UI Dashboard React component
import PageLayout from "examples/LayoutContainers/PageLayout";

// Local Components
import EmailInput from "./components/Inputs/Email";
import ResetPasswordButton from "./components/Buttons/ResetPassword";
import SignInLink from "./components/Buttons/SignInLink";

// API
import { fetchForgotPassword } from 'api/auth';

import './styles.css';

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const info = useRef({
    email: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const {
      current: {
        email
      }
    } = info;

    if (email) {
      const response = await fetchForgotPassword(email);
      dispatch(setInformativeMessage({ ...response }));
    } else {
      dispatch(setInformativeMessage({
        success: false,
        message: 'Email is required.'
      }));
    }
    setLoading(false);
  };

  return (
    <PageLayout>
      <div className="authPage">
        <div className="wrapper">
          <div className="formContainer">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <EmailInput info={info} />
                <ResetPasswordButton loading={loading} />
              </div>
            </form>
          </div>
          <SignInLink />
        </div>
      </div>
    </PageLayout>
  );
}
