// @mui material components
import { Grid, Alert, } from "@mui/material";
import "./styles.css";

export default function ProfileAlert({ setError, messageError }) {

    return (
        <Grid className="content__alert" container spacing={1}  >
            <Grid item xs={12} lg={3}>
                <Alert severity="error" onClose={() => { setError(false) }}>{messageError}</Alert>
            </Grid>
        </Grid>
    );

}