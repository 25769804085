import { parseActiveExchangesFromKeys } from "utils/parseActiveExchangesFromKeys";

export const getSummaryWallet = wallets => {
    let sum = []
    wallets.forEach(wallet => {
        wallet.data.forEach(coin => {
            const { symbol, total } = coin
            let isSymbol = false;
            const arr = [];
            sum.map(item => {
                if (item.symbol === symbol) {
                    arr.push({ ...item, summary: item.summary + total });
                    isSymbol = true;
                } else {
                    arr.push(item);
                }
            })
            isSymbol ? sum = arr : sum = [...sum, { symbol, summary: total }]
        });
    });
    return sum
}

export const getCoinsNames = wallets => {
    const symbolSet = new Set();
    wallets.forEach(wallet => {
        wallet.coins.forEach(coin => {
            symbolSet.add(coin.symbol);
        });
    });
    return Array.from(symbolSet);
}

export const parseWalletsFromRedux = (walletsFromRedux, keys, cryptoCompareCoins) => {
    const sumOfWallets = {
        name: "all",
        status: "success",
        coins: [],
        totalDollarValue: 0
    }
    const parsedWallets = Object.entries(walletsFromRedux).filter(wallet => (parseActiveExchangesFromKeys(keys).map(exchange => exchange)).includes(wallet[0])).map(wallet => {
        let totalDollarValue = 0
        const coins = wallet[1].data.map(coin => {
            const cryptoCompareCoin = cryptoCompareCoins.find(cryptoCompareCoin => cryptoCompareCoin.symbol === coin.symbol)
            const dollarValue = cryptoCompareCoin ? cryptoCompareCoin.dollarPrice * coin.free : 0
            totalDollarValue += dollarValue
            return {
                amount: coin.free,
                symbol: coin.symbol,
                dollarValue: dollarValue,
                img_url: cryptoCompareCoin ? cryptoCompareCoin.img_url : ""
            }
        }).sort((a, b) => b.dollarValue - a.dollarValue)
        coins.forEach(coin => {
            const coinIndex = sumOfWallets.coins.findIndex(c => c.symbol === coin.symbol)
            if (coinIndex !== -1) {
                const selectedCoin = sumOfWallets.coins[coinIndex]
                sumOfWallets.coins[coinIndex] = {
                    ...selectedCoin,
                    amount: coin.amount + selectedCoin.amount,
                    dollarValue: coin.dollarValue + selectedCoin.dollarValue
                }
            } else {
                sumOfWallets.coins.push(coin)
            }
        })
        sumOfWallets.totalDollarValue += totalDollarValue
        return {
            name: wallet[0],
            status: wallet[1].status,
            coins: coins,
            totalDollarValue: totalDollarValue
        }
    })
    sumOfWallets.coins.sort((a, b) => b.dollarValue - a.dollarValue)
    parsedWallets.unshift(sumOfWallets)
    return parsedWallets
}