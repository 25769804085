import { updateKeysActive } from "api/keys";
import { updateKeys, fetchKeys } from "api/keys";
import { SET_KEYS, UPDATE_KEY_ACTIVE, SET_KEYS_STATUS, ADD_OR_UPDATE_EXCHANGE_KEYS, SET_EXCHANGE_KEYS_STATUS } from "constants/actionTypes";
import { STATUS } from "constants/fetchStatus";
import { getOrders } from "./orders";
import { store } from "index";
import { getWallets } from "./wallets";

/***** Redux actions that end up in the store *****/
export const getKeys = () => async dispatch => {
    try {
        dispatch({ type: SET_KEYS_STATUS, payload: STATUS.LOADING });
        const email = store.getState().currentUser.data.email
        const data = await fetchKeys(email);
        dispatch({ type: SET_KEYS, payload: data });
        dispatch({ type: SET_KEYS_STATUS, payload: STATUS.OK });
        dispatch(getOrders())
        dispatch(getWallets())
    } catch (error) {
        dispatch({ type: SET_KEYS_STATUS, payload: STATUS.ERROR });
    };
};

export const saveKeys = (email, privateKey, publicKey, password, exchange) => async dispatch => {
    try {
        dispatch({ type: SET_EXCHANGE_KEYS_STATUS, payload: { status: STATUS.LOADING, exchange } });
        const data = await updateKeys(email, privateKey, publicKey, password, exchange);
        dispatch({ type: ADD_OR_UPDATE_EXCHANGE_KEYS, payload: data });
        dispatch({ type: SET_EXCHANGE_KEYS_STATUS, payload: { status: STATUS.OK, exchange } });
        dispatch(getOrders())
        dispatch(getWallets())
    } catch (error) {
        dispatch({ type: SET_EXCHANGE_KEYS_STATUS, payload: { status: STATUS.ERROR, exchange } });
    };
};

export const toggleKeysActive = (email, exchange, active) => async dispatch => {
    try {
        dispatch({ type: SET_EXCHANGE_KEYS_STATUS, payload: { status: STATUS.LOADING, exchange } });
        const data = await updateKeysActive(email, exchange, active);
        dispatch({ type: UPDATE_KEY_ACTIVE, payload: { active: data, exchange } });
        dispatch({ type: SET_EXCHANGE_KEYS_STATUS, payload: { status: STATUS.OK, exchange } });
        dispatch(getOrders())
        dispatch(getWallets())
    } catch (error) {
        dispatch({ type: SET_EXCHANGE_KEYS_STATUS, payload: { status: STATUS.ERROR, exchange } });
        dispatch({ type: SET_KEYS_STATUS, payload: STATUS.ERROR });
    };
};