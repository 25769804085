import { Link } from 'react-router-dom';

import './styles.css';

export default function SignUpLink() {
      
  return (
    <div className="linkContainer">
      <div>
        Don't have an account yet? {" "}
        <Link to="/auth/register">Register now</Link>
      </div>
    </div>
  );
}
