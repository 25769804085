import React, { useEffect } from 'react';

import { useParams, Navigate, useLocation } from 'react-router-dom';


// Utils
import auth from '../../../utils/auth';
import request from '../../../utils/request';
import { ROUTES } from 'constants';

const redirectUser = path => {
  <Navigate to={path} />;
};

export default function ConnectPage() {
  let { provider } = useParams();
  let { search } = useLocation()

  useEffect(() => {
    const requestURL = `auth/${provider}/callback${search}`;

    request(requestURL, { method: 'GET' })
      .then(response => {
        auth.setToken(response.jwt, true);
        auth.setUserInfo(response.user, true);
        redirectUser('/');
      })
      .catch(err => {
        console.log(err.response.payload);
        redirectUser(ROUTES.LOGIN);
      });
  }, [])

  return (
    <div>
      <h1>Retrieving your token and checking its validity</h1>
    </div>
  );
}