import { useSelector } from "react-redux"

// @mui material components
import { Grid } from '@mui/material';

// Local components
import UserField from './components/UserField';

// Data
import { profileData } from 'layouts/Common/UserProfile/data/profileData';
import { USER_TYPES } from "constants";

export default function InformationData({ profileInfo }) {

    const currentUser = useSelector(state => state.currentUser.data);

    const { tipo_usuario } = currentUser;

    return (
        <Grid container mt='1rem'>
            {profileData.map(data => {
                if (tipo_usuario !== USER_TYPES.CLIENT) {
                    switch (data.name) {
                        case "Deposit Type":
                            return null;
                        case "Amount":
                            return null;
                        default:
                            return <UserField {...data} key={data.id} profileInfo={profileInfo} />;
                    }
                }
                return <UserField {...data} key={data.id} profileInfo={profileInfo} />;
            })}
        </Grid>
    );

}