
import { useSelector } from "react-redux"
import AdminPanel from "../Adminpanel";
import ClientDashboard from "./Client";
import TraderDashboard from "./Trader";
import { USER_TYPES } from "constants";

export default function ProfessionalDashboard() {
  const currentUser = useSelector(state => state.currentUser.data);
  const { tipo_usuario } = currentUser;

  return (
    <>
      {tipo_usuario === USER_TYPES.ADMIN && <AdminPanel />}
      {tipo_usuario === USER_TYPES.CLIENT && <ClientDashboard />}
      {tipo_usuario === USER_TYPES.TRADER && <TraderDashboard />}
      {tipo_usuario === USER_TYPES.PORTFOLIO && <TraderDashboard />}
    </>
  );
}
