import { useState } from 'react';

// Soft UI Dashboard React component
import SoftInput from "components/SoftInput";
import SoftTypography from 'components/SoftTypography';
import InputErrors from 'components/InputErrors';

import './styles.css';

export default function EmailInput({ info }) {

  const {
    current: {
      email
    }
  } = info;

  const [inputError, setInputError] = useState([]);

  const handleChange = (event) => {
    const { target: { value } } = event;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    info.current.email = value;

    if (!value) {
      setInputError(["Email is required."]);
    } else if (!emailRegex.test(value)) {
      setInputError(["Invalid email address."]);
    } else {
      setInputError([]);
    }
  };

  return (
    <>
      <SoftTypography>
        Email
      </SoftTypography>
      <SoftInput
        className="user__input"
        type="email"
        autoFocus
        label="Email"
        name="email"
        onChange={handleChange}
        placeholder="user@mail.com"
        validations={{ required: true }}
        value={email}
      />
      <InputErrors
        className="errorsContainer"
        errors={inputError}
      />
    </>
  );
}
