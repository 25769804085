// Soft UI Dashboard React components
import SoftButton from "components/SoftButton";

// @mui material components
import { Grid } from "@mui/material";

export default function CancelButton({ editLayout, setEditLayout }) {

    const handleClick = () => {
        setEditLayout(!editLayout);
    }

    return (
        <Grid item xs={6} md={4} xl={2} >
            <SoftButton
                onClick={handleClick}
                style={{ marginTop: "25px" }}
                variant={"gradient"}
                color="error"
                fullWidth
            >
                Cancel
            </SoftButton>
        </Grid>
    );

}