import { v4 as uuidv4 } from 'uuid';
import { exchangesData } from 'data/exchanges';
import Exchange from './components/Exchange';

// @mui material components
import { Grid, Card } from "@mui/material";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

import "./index.css";

function Exchanges() {
    return (
        <Grid container justifyContent="center" spacing={4} mt={3} mb={3} >
            <Grid item xs={12} md={11} xl={10}>
                <SoftBox>
                    {exchangesData.map(exchange => <Exchange key={uuidv4()} exchange={exchange} />)}
                </SoftBox>
            </Grid>
        </Grid>
    )
}

export default Exchanges;
