import { useState } from 'react';
import { useSelector } from "react-redux";

// Soft UI Dashboard React component
import SoftInput from "components/SoftInput";

import './styles.css';

export default function IbanInput() {
  const currentUser = useSelector(state => state.currentUser.data);
  const { iban } = currentUser;
  
  return (
    <>
      <SoftInput
        className="iban__input"
        name="iban"
        label="IBAN"
        type="text"
        placeholder="Your IBAN"
        value={iban || ""}
        disabled
      />
    </>
  );
};
