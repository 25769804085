// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Card } from "@mui/material";
import roundNum from "utils/roundNum";
import CryptoIcon from "layouts/Common/CryptoIcon";

export default function SymbolBox({ coin, walletStatus }) {
    const { symbol, amount, dollarValue, img_url } = coin

    return (
        <Grid item lg={2.4} md={3} sm={4}>
            <Card style={{ opacity: walletStatus === "loading" ? 0.3 : 1 }}>
                <SoftBox m={3} mb={1} ml={2} mr={1}>
                    <CryptoIcon img_url={img_url} />
                    <SoftTypography mb={1} className="title-card">
                        {symbol}
                    </SoftTypography>
                    <SoftTypography className="amount-card">
                        {symbol}: <span className="amount-crypto">{roundNum(parseFloat(amount))}</span>
                    </SoftTypography>
                    <SoftTypography className="amount-card">
                        $: <span className="amount-fiat">{dollarValue.toFixed(2)}</span>
                    </SoftTypography>
                </SoftBox>
            </Card>
        </Grid>
    )
}