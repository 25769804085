import { v4 as uuidv4 } from 'uuid';

// @mui material components
import { Grid } from '@mui/material';

// Local components
import ExchangeField from './components/ExchangeField';

// Data
import { exchangesData } from 'data/exchanges';

export default function ExchangesData({ isExchangeOpen, setIsExchangeOpen }) {

    return (
        <Grid container mt='1rem' >
            {exchangesData.map(data => <ExchangeField {...data} key={uuidv4()} isExchangeOpen={isExchangeOpen} setIsExchangeOpen={setIsExchangeOpen} />)}
        </Grid>
    );

}