import axios from "axios";
import { BACK_API_AMV } from "apiconfig";
import { store } from "index";

export async function fetchTokensBalances() {
    const url = `${BACK_API_AMV}/tokens/balances`;
    const headers = { 'Authorization': `Bearer ${store.getState().currentUser.data.jwtToken}` };
    return await axios
        .get(url, { headers })
        .then(res => res.data);
};

export async function fetchGlobalShifts() {
    const url = `${BACK_API_AMV}/global_shifts`;
    const headers = { 'Authorization': `Bearer ${store.getState().currentUser.data.jwtToken}` };
    return await axios
        .get(url, { headers })
        .then(res => res.data);
};