// @mui material components
import { Grid } from "@mui/material";
import SoftButton from "components/SoftButton";
import { cardButton } from "examples/Sidenav/styles/sidenavCard";
import { useSoftUIController } from "context";
import '../../index.css';

export default function TradeButton({ readyToTrade, setChartOpened }) {

    const [controller] = useSoftUIController();
    const { sidenavColor } = controller;

    const openChart = () => {
        setChartOpened(true)
    }

    return (
        <Grid item lg={2}>
            <SoftButton disabled={!readyToTrade} onClick={openChart} fullWidth sx={(theme) => cardButton(theme, { sidenavColor })}>
                Trade
            </SoftButton>
        </Grid>
    );
}
