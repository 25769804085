// Local components
import WalletInput from '../Inputs/Wallet';
import AmountInput from '../Inputs/Amount';

import './styles.css';
import SliderAmountWithdrawal from '../Inputs/SliderAmount';

export default function Crypto({ setWithdrawal, withdrawal, limit }) {

  return (
    <div className="container__crypto">
      <WalletInput />
      <SliderAmountWithdrawal setWithdrawal={setWithdrawal} withdrawal={withdrawal} maxAmount={limit} />
      <AmountInput setWithdrawal={setWithdrawal} withdrawal={withdrawal} maxAmount={limit} />
    </div>
  )
}
