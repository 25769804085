import { fetchTokensBalances, fetchGlobalShifts } from "api/charts";
import { CHARTS_STATUS, SET_TOKENS_DATA, SET_GLOBAL_SHIFTS_DATA } from "constants/actionTypes";
import { STATUS } from "constants/fetchStatus";

/***** Redux actions that end up in the store *****/
export const getTokensBalances = () => async dispatch => {
    try {
        dispatch({ type: CHARTS_STATUS, payload: STATUS.LOADING });
        const data = await fetchTokensBalances();
        dispatch({ type: SET_TOKENS_DATA, payload: data });
        dispatch({ type: CHARTS_STATUS, payload: STATUS.OK });
    } catch (error) {
        dispatch({ type: CHARTS_STATUS, payload: STATUS.ERROR });
    };
};

export const getGlobalShifts = () => async dispatch => {
    try {
        dispatch({ type: CHARTS_STATUS, payload: STATUS.LOADING });
        const data = await fetchGlobalShifts();
        dispatch({ type: SET_GLOBAL_SHIFTS_DATA, payload: data });
        dispatch({ type: CHARTS_STATUS, payload: STATUS.OK });
    } catch (error) {
        dispatch({ type: CHARTS_STATUS, payload: STATUS.ERROR });
    };
};