import { v4 as uuidv4 } from "uuid";
import SoftButton from "components/SoftButton";
import "../../styles.css";

export default function WalletNavigator({ selectedWallet, handleSelectedWallet, wallets }) {
    return (
        <>
            {wallets.map(wallet => {
                return <SoftButton key={uuidv4()} className={selectedWallet === wallet.name ? "selected" : "notSelected"} variant="text" color="dark" value={wallet.name} onClick={handleSelectedWallet}>{wallet.name}</SoftButton>
            })}
        </>
    )
}