import { useSelector } from "react-redux"

// @mui material components
import { Grid, Switch } from '@mui/material';

// Soft UI Dashboard React components
import SoftTypography from 'components/SoftTypography';

export default function ExchangeField({ img, label, value, isExchangeOpen, setIsExchangeOpen }) {

    const keysData = useSelector(state => state.keys.data.find(data => data.exchange === value))
    const active = keysData?.isActive || false

    const handleClick = () => {
        setIsExchangeOpen(!isExchangeOpen)
    }

    return (
        <Grid
            container
            alignItems="center"
            mb='0.8rem'
        >
            <Grid item xs={1} md={1} xl={1}>
                <img width={"auto"} height={28} src={img}></img>
            </Grid>
            <Grid item xs={7} md={6} xl={5} >
                <SoftTypography variant="h6" >
                    {label}
                </SoftTypography>
            </Grid>
            <Grid item xs={4} md={5} xl={6} pb='0.4rem'>
                <Switch
                    value=""
                    checked={active}
                    onClick={handleClick}
                />
            </Grid>
        </Grid>
    );

}