import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import {
    Chart as ChartJS,
    BarElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    ArcElement
} from "chart.js"
ChartJS.register(
    BarElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    ArcElement
)

export default function SummaryChartBars({ selectedWallet, walletStatus }) {
    const { coins } = selectedWallet
    const limitedCoins = coins.slice(0, 10)
    return (
        <VerticalBarChart
            title="Fiat equivalent"
            status={walletStatus}
            chart={{
                labels: limitedCoins.map(coin => coin.symbol),
                datasets: [{
                    label: "Value",
                    color: "dark",
                    data: limitedCoins.map(coin => coin.dollarValue),
                }],
            }}
        />
    )
}