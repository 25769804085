import { useState } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

// formik components
import { ErrorMessage } from "formik";

// NewUser page components
import FormField from "../FormField";
import { IS_CLIENT_BASTION } from "apiconfig";

function UserInfo({ formData }) {
  const { formField, values, errors, touched } = formData;
  const [type, setType] = useState('...');
  const { firstName, lastName, email, password, repeatPassword, depositType, account, wallet, iban } = formField;
  const {
    firstName: firstNameV,
    lastName: lastNameV,
    email: emailV,
    password: passwordV,
    repeatPassword: repeatPasswordV,
    account: accountV
  } = values;

  const handleSetState = ({ target }) => {
    const { value } = target;
    setType(value);
    values.depositType = value;
  };

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          About me
        </SoftTypography>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          Mandatory informations
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={firstName.type}
              label={firstName.label}
              name={firstName.name}
              value={firstNameV}
              placeholder={firstName.placeholder}
              error={errors.firstName && touched.firstName}
              success={firstNameV.length > 0 && !errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={lastName.type}
              label={lastName.label}
              name={lastName.name}
              value={lastNameV}
              placeholder={lastName.placeholder}
              error={errors.lastName && touched.lastName}
              success={lastNameV.length > 0 && !errors.lastName}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              value={emailV}
              placeholder={email.placeholder}
              error={errors.email && touched.email}
              success={emailV.length > 0 && !errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={password.type}
              label={password.label}
              name={password.name}
              value={passwordV}
              placeholder={password.placeholder}
              error={errors.password && touched.password}
              success={passwordV.length > 0 && !errors.password}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {!IS_CLIENT_BASTION &&
            <Grid item xs={12} sm={6}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  {depositType.label}
                </SoftTypography>
              </SoftBox>
              <Select
                input={<SoftInput />}
                value={type}
                onChange={handleSetState}
              >
                <MenuItem disabled value="...">...</MenuItem>
                <MenuItem value="crypto">Crypto</MenuItem>
                <MenuItem value="iban">IBAN</MenuItem>
              </Select>
              <SoftBox mt={0.75}>
                <SoftTypography component="div" variant="caption" color="error">
                  <ErrorMessage name={depositType.name} />
                </SoftTypography>
              </SoftBox>
            </Grid>
          }
          <Grid item xs={12} sm={6}>
            {IS_CLIENT_BASTION &&
              <FormField
                type={iban.type}
                label={iban.label}
                name={account.name}
                value={accountV}
                placeholder={iban.placeholder}
                error={errors.iban && touched.iban}
                success={accountV.length > 0 && !errors.iban}
              />
            }
            {type === "crypto" ? (
              <FormField
                type={wallet.type}
                label={wallet.label}
                name={account.name}
                value={accountV}
                placeholder={wallet.placeholder}
                error={errors.wallet && touched.wallet}
                success={accountV.length > 0 && !errors.wallet}
              />
            ) : type === 'iban' ? (
              <FormField
                type={iban.type}
                label={iban.label}
                name={account.name}
                value={accountV}
                placeholder={iban.placeholder}
                error={errors.iban && touched.iban}
                success={accountV.length > 0 && !errors.iban}
              />
            ) : null}
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <FormField
              type={repeatPassword.type}
              label={repeatPassword.label}
              name={repeatPassword.name}
              value={repeatPasswordV}
              placeholder={repeatPassword.placeholder}
              error={errors.repeatPassword && touched.repeatPassword}
              success={repeatPasswordV.length > 0 && !errors.repeatPassword}
              inputProps={{ autoComplete: "" }}
            />
          </Grid> */}
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;
