import ReportsDoughnutChart from "examples/Charts/DoughnutCharts/ReportsDoughnutChart";
import {
    Chart as ChartJS,
    BarElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    ArcElement
} from "chart.js"
ChartJS.register(
    BarElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    ArcElement
)

export default function SummaryChartPie({ selectedWallet, walletStatus }) {
    const { totalDollarValue, coins } = selectedWallet
    const limitedCoins = coins.slice(0, 5)
    return (
        <ReportsDoughnutChart
            title="Percentage"
            count={{ number: `${totalDollarValue.toFixed(2)}$`, text: "Total" }}
            status={walletStatus}
            chart={{
                labels: limitedCoins.map(coin => coin.symbol),
                datasets: {
                    label: "Value",
                    backgroundColors: ["primary", "secondary", "info", "success", "warning"],
                    data: limitedCoins.map(coin => `${(coin.dollarValue / totalDollarValue * 100).toFixed(2)}`),
                }
            }}
        />
    )
}