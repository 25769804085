// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";

export default function ExchangeLabel({ label }) {

    return (
        <SoftTypography className="crypto-market-name" variant="section">
            {label}
        </SoftTypography>
    )
}