import { useParams, useNavigate } from 'react-router-dom';

// Redux
import { useSelector } from "react-redux";

// Local components
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Register from './components/Register';
import Lock from './components/Lock';
import Deposit from 'layouts/Common/Deposit';
import Message from 'layouts/Common/Status/Message';

import { ROUTES } from 'constants';

export default function AuthPage() {

  const { message } = useSelector(state => state.informativeMessage);
  const navigate = useNavigate();

  let { authType } = useParams();
  let componentToRender = null;

  switch (authType) {
    case 'login':
      componentToRender = <Login />;
      break;

    case 'forgot-password':
      componentToRender = <ForgotPassword />;
      break;

    case 'reset-password':
      componentToRender = <ResetPassword />;
      break;

    case 'register':
      componentToRender = <Register />;
      break;

    case 'deposit':
      componentToRender = <Deposit />;
      break;

    case 'lock':
      componentToRender = <Lock />;
      break;

    default:
      navigate(ROUTES.LOGIN);
      break;
  }

  return (
    <>
      {message && <Message />}
      {componentToRender}
    </>
  );
}
