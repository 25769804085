// Config at .env when deploying: 
export const ENV = process.env.REACT_APP_NODE_ENV;
export const CLIENT_NAME = process.env.REACT_APP_CLIENT_NAME;

export const DOMAIN = process.env.REACT_APP_DOMAIN;
export const BACK_API = process.env.REACT_APP_BACK_URL;
export const BACK_API_AMV = process.env.REACT_APP_API_AMV;
export const IS_CLIENT_BASTION = process.env.REACT_APP_IS_CLIENT_BASTION === 'true';
export const BACK_API_AMV_CHARTS = process.env.REACT_APP_API_AMV_CHARTS;
export const BACK_API_PANICBT = process.env.REACT_APP_API_PANICBT;
export const BOT_CONTAINER_NAME = process.env.REACT_APP_BOT_CONTAINER_NAME;
