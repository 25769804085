import { useState } from 'react';

// react-router-dom components
import { Link } from "react-router-dom";

// Soft UI Dashboard React component
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

// Material UI
import { Switch } from '@mui/material';

import { ROUTES } from 'constants';

import './styles.css';

export default function RememberMeSwitch({ sesion }) {

  const [isRemember, setIsRemember] = useState(false);

  const handleChange = ({ target }) => {
    setIsRemember(!isRemember);
    sesion.current.rememberMe = target.checked;
  };

  return (
    <SoftBox
      display='flex'
      justifyContent="space-between"
      alignItems="center"
    >
      <SoftBox>
        <SoftTypography
          className="rememberme"
          variant={"paragraph"}
          mr="0.5rem"
        >
          Remember me
        </SoftTypography>
        <Switch
          label="Remember Me"
          name="rememberme"
          checked={isRemember}
          onChange={handleChange}
          validations={{ required: true }}
        />
      </SoftBox>
      <SoftTypography
        component={Link}
        to={ROUTES.FORGOT}
        variant="button"
        color="info"
        fontWeight="medium"
        textGradient
      >
        Forgot your password?
      </SoftTypography>
    </SoftBox>
  );
}
