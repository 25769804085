import { LOADING_ORDERS, SET_ORDERS } from "constants/actionTypes"
import { fetchOrders } from "api/orders"
import { parseActiveExchangesFromKeys } from "utils/parseActiveExchangesFromKeys"
import { store } from "index"

/***** Redux actions that end up in the store *****/

export const getOrders = () => async dispatch => {
    try {
        dispatch({ type: LOADING_ORDERS, payload: true })
        const state = store.getState()
        const email = state.currentUser.data.email
        const keys = state.keys.data
        const exchanges = parseActiveExchangesFromKeys(keys)
        const orders = await fetchOrders(email, exchanges)
        dispatch({ type: SET_ORDERS, payload: { orders } })
        dispatch({ type: LOADING_ORDERS, payload: false })
    } catch (error) {
        console.log(error.message)
    }
}