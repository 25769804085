import { ROUTES, USER_TYPES } from "constants"

// If url is /, RootUrl component will redirect to the third allowed url
const USER_PERMISSIONS = {
    [USER_TYPES.CLIENT]: [
        ROUTES.ROOT,
        ROUTES.AUTH,
        ROUTES.HOME,
        ROUTES.TRANSACTIONS,
        ROUTES.PROFILE,
        ROUTES.DEPOSIT,
        ROUTES.WITHDRAWAL
    ],
    [USER_TYPES.TRADER]: [
        ROUTES.ROOT,
        ROUTES.AUTH,
        ROUTES.HOME,
        ROUTES.TRADE,
        ROUTES.ORDERS,
        ROUTES.PROFILE,
        ROUTES.WALLET
    ],
    [USER_TYPES.PORTFOLIO]: [
        ROUTES.ROOT,
        ROUTES.AUTH,
        ROUTES.HOME,
        ROUTES.PROFILE,
        ROUTES.WALLET
    ],
    [USER_TYPES.ADMIN]: [
        ROUTES.ROOT,
        ROUTES.AUTH,
        ROUTES.HOME,
        ROUTES.PROFILE
    ]
}

const getAllowedRoutes = userType => {
    return USER_PERMISSIONS[userType || USER_TYPES.CLIENT]
}

export default function filterRoutes(routes, userType) {
    const allowedRoutes = getAllowedRoutes(userType)
    const filteredRoutes = routes.filter(route => allowedRoutes.some(allowedRoute => allowedRoute === route.route))
    return filteredRoutes
}