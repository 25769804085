import { useState, useRef } from 'react';
import { useSelector } from "react-redux"

// @mui material components
import { Grid } from "@mui/material";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Utils
import ProfileAlert from '../Alert';
import EditLayout from '../EditLayout';
import InformationLayout from '../InformationLayout';
import ProfileAnimation from '../Animation';
import ExchangesLayout from '../ExchangesLayout';
import { USER_TYPES } from 'constants';

export default function ProfileInformation({ isExchangeOpen, setIsExchangeOpen }) {

    const [editLayout, setEditLayout] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [error, setError] = useState(null);
    const [messageError, setMessageError] = useState('')
    const currentUser = useSelector(state => state.currentUser.data);
    const { tipo_usuario } = currentUser;

    const profileInfo = useRef({
        id: currentUser.id,
        name: currentUser.name,
        surname: currentUser.surname,
        email: currentUser.email,
        depositype: currentUser.wallet ? "Crypto" : currentUser.iban ? "Bank" : "",
        amount: currentUser.amount,
    });

    const handleSubmit = async () => {
        const {
            id,
            name,
            surname,
            email
        } = profileInfo.current;

        const data = {
            name,
            surname,
            email
        };
    }

    return <>
        {isCompleted ? (
            <ProfileAnimation
                setIsCompleted={setIsCompleted}
                error={error}
            />
        ) : (
            <>
                {editLayout ? (
                    <SoftBox mt={5} mb={3}>
                        {error && (
                            <ProfileAlert
                                setError={setError}
                                messageError={messageError}
                            />
                        )}
                        <EditLayout
                            editLayout={editLayout}
                            setEditLayout={setEditLayout}
                            profileInfo={profileInfo}
                            handleSubmit={handleSubmit}
                        />
                    </SoftBox>
                ) : (
                    <Grid container justifyContent="center" spacing={4} mt={3} mb={3} >
                        <Grid item xs={12} md={6} xl={5}>
                            <SoftBox>
                                <InformationLayout
                                    editLayout={editLayout}
                                    setEditLayout={setEditLayout}
                                    profileInfo={profileInfo}
                                />
                            </SoftBox>
                        </Grid>
                        {(tipo_usuario == USER_TYPES.TRADER || tipo_usuario == USER_TYPES.PORTFOLIO) && (
                            <Grid item xs={12} md={6} xl={5}>
                                <SoftBox>
                                    <ExchangesLayout
                                        isExchangeOpen={isExchangeOpen}
                                        setIsExchangeOpen={setIsExchangeOpen}
                                        profileInfo={profileInfo}
                                    />
                                </SoftBox>
                            </Grid>
                        )}
                    </Grid>
                )
                }
            </>
        )
        }
    </>
}