/***** We receive an action and set de data to the store *****/
import { SET_WALLET_DATA, WALLET_STATUS, CLEAR_WALLET } from "constants/actionTypes"

export default (wallets = [], action) => {
    switch (action.type) {
        case SET_WALLET_DATA:
            return { ...wallets, [action.payload.wallet]: { ...wallets[action.payload.wallet], data: action.payload.data } }
        case WALLET_STATUS:
            // Sets data to {} if there was nothing previously
            const hasData = wallets.hasOwnProperty(action.payload.wallet) && wallets[action.payload.wallet]?.data
            const newData = hasData ? wallets[action.payload.wallet].data : []
            return { ...wallets, [action.payload.wallet]: { ...wallets[action.payload.wallet], data: newData, status: action.payload.status } }
        case CLEAR_WALLET:
            return [];
        default:
            return wallets
    }
}