// Soft UI Dashboard React components
import Stack from '@mui/material/Stack';

// Local Components
import CancelButton from "./Cancel";
import DepositButton from "./Deposit";

export default function Buttons({ loading }) {

  return (
    <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} >
      <CancelButton loading={loading} />
      <DepositButton loading={loading} />
    </Stack>
  );
}
