import Slider from "@mui/material/Slider";
import Box from '@mui/material/Box';

export default function SliderAmountWithdrawal({ setWithdrawal, withdrawal, maxAmount }) {
    const { amount } = withdrawal;
    const handleValueFunction = ({ target }) => {
        setWithdrawal(prev => ({ ...prev, amount: parseFloat(target.value) }));
    };

    const getMarks = () => {
        const response = [];
        if (maxAmount) {
            for (let index = 0; index <= 10; index++) {
                response.push({
                    value: index === 0 ? 0 : maxAmount * (index * 10 / 100),
                    label: (index === 0 || index === 5 || index === 10) && `${index * 10}%`
                });
            };
        };
        return response;
    };

    return (
        <Box mb={1}>
            <Slider
                color="info"
                marks={getMarks()}
                value={amount}
                onChange={handleValueFunction}
                step={maxAmount / 100}
                size="small"
                min={0}
                max={maxAmount}
                disabled={!maxAmount}
            />
        </Box>
    )
}