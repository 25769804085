import SoftTypography from "components/SoftTypography";
import Transaction from "./Transaction";
import SoftBox from "components/SoftBox";

export default function Transactions({ deposits, withdrawals }) {

    const mappedDeposits = deposits.map(deposit => ({ ...deposit, transactionType: "deposit" }))
    const mappedWithdrawals = withdrawals.map(withdrawal => ({ ...withdrawal, transactionType: "withdrawal" }))
    const userTransactions = [...mappedDeposits, ...mappedWithdrawals]

    return <>
        {userTransactions.length === 0 &&
            <SoftBox m={3}>
                <SoftTypography variant="body1" color="secondary">
                    No transactions yet
                </SoftTypography>
            </SoftBox>
        }
        {userTransactions.map(transaction => {
            return <Transaction transaction={transaction} />
        })}
    </>
}