import { useState } from 'react';

import { QRCodeSVG } from 'qrcode.react';

// Redux
import { useDispatch } from "react-redux";
import { setInformativeMessage } from 'redux/actions/informativeMessage';

// Soft UI Dashboard React components
import SoftTypography from 'components/SoftTypography';

/// @mui material components
import Stack from '@mui/material/Stack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// Local components
import WalletInput from '../Inputs/Wallet';
import AmountInput from '../Inputs/Amount';

import { ACCOUNTS } from 'constants';

import './styles.css';

export default function Crypto({ deposit, setDeposit }) {

  const dispatch = useDispatch();
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handleOnCLick = () => {
    navigator.clipboard.writeText(ACCOUNTS.WALLET);
    dispatch(setInformativeMessage({ success: true, message: 'Copied to Clipboard!' }));
  };

  return (
    <div className="container__crypto">
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={4} marginBottom="20px">
        <QRCodeSVG
          value={ACCOUNTS.WALLET}
          size={200}
          bgColor="#FFFFFF"
          fgColor="#344776"
          level="Q"
        />
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ cursor: 'pointer' }}
          onClick={handleOnCLick}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <SoftTypography
            variant="button"
            sx={{ fontSize: 13.5 }}
          >
            {isHovering ? <ContentCopyIcon /> : '\u00A0'}
          </SoftTypography>
          <SoftTypography
            variant="button"
            sx={{ fontSize: 13.5 }}
          >
            WALLET:
          </SoftTypography>
          <SoftTypography
            variant="button"
            sx={{ fontSize: 13.5 }}
          >
            {ACCOUNTS.WALLET}
          </SoftTypography>
        </Stack>
      </Stack>
      <WalletInput />
      <AmountInput deposit={deposit} setDeposit={setDeposit} />
    </div>
  )
}
