import { useSelector } from "react-redux"

// @mui material components
import { Grid } from "@mui/material";

// Local components
import ProfileInfoButton from "./ProfileInfo";
import WithdrawalButton from "./Withdrawal";
import DepositButton from "./Deposit";
import SignoutButton from "./SingOut";
import { USER_TYPES } from "constants";

export default function Buttons({ isExchangeOpen, setIsExchangeOpen }) {
    const currentUser = useSelector(state => state.currentUser.data);
    const { tipo_usuario } = currentUser;
    const shouldRenderClientButtons = !isExchangeOpen;

    return (
        <Grid item xs={12} md={5} xl={6} >
            <Grid container spacing={1} alignItems="center" justifyContent="center" >
                {isExchangeOpen && <ProfileInfoButton isExchangeOpen={isExchangeOpen} setIsExchangeOpen={setIsExchangeOpen} />}
                {(shouldRenderClientButtons && tipo_usuario === USER_TYPES.CLIENT) && <WithdrawalButton />}
                {(shouldRenderClientButtons && tipo_usuario === USER_TYPES.CLIENT) && <DepositButton />}
                <SignoutButton />
            </Grid>
        </Grid>
    );
}

