export const getLastThreeMonths = (startDate) => {
    // Get today's date
    const currentDate = new Date();

    // Array to store the names of the last three months
    const lastThreeMonths = [];

    // Loop through the last 90 days
    for (let i = 0; i < startDate; i++) {
        const date = new Date();
        date.setDate(currentDate.getDate() - i);
        lastThreeMonths.unshift(date.toISOString().split('T')[0]);
    }

    return lastThreeMonths;
};
