export default function parseDate(date) {
    if (date) {
        // Parse the string into a Date object
        const parsedDate = new Date(date);

        // Extract date, month, and year components
        const year = parsedDate.getFullYear();
        const month = parsedDate.getMonth() + 1; // Months are zero-based, so add 1
        const day = parsedDate.getDate();

        // Create the short date format (assuming month and day are always two digits)
        const shortDateFormat = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
        return shortDateFormat;
    }

    return null;
};