// Soft UI Dashboard React components
import Stack from '@mui/material/Stack';

// Local Components
import CancelButton from "./Cancel";
import WithdrawalButton from './Withdrawal';

export default function Buttons({ loading, limit }) {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} >
      <CancelButton loading={loading} />
      {limit ? <WithdrawalButton loading={loading} /> : null}
    </Stack>
  );
}
