// sweetalert2 components
import Swal from "sweetalert2";
// Soft UI Dashboard React components
import SoftButton from "components/SoftButton";
// @mui material components
import { Grid } from "@mui/material";
import { deleteUser } from "api/users";

function DeleteUser({ userId, userEmail, refreshButtonAction, cancelEdit }) {
  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal.fire({
      title: `Delete user ${userEmail}?`,
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, DELETE!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUser(userId)
          .then(() => {
            cancelEdit()
            refreshButtonAction()
            Swal.fire(
              'Deleted!',
              `User ${userEmail} has been deleted`,
              'success'
            )
          })
          .catch(() => {
            Swal.fire(
              'Error',
              `User ${userEmail} could not be deleted`,
              'error'
            )
          })

      }
    })
  };

  return (
    <Grid item xs={6} md={4} xl={2}>
      <SoftButton
        onClick={showAlert}
        style={{ marginTop: "25px" }}
        variant={"gradient"}
        color="error"
        fullWidth
      >
        Delete user
      </SoftButton>
    </Grid>
  );
}

export default DeleteUser;
