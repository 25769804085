// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useEffect, useState } from "react";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SummaryChartBars from "./components/Charts/SummaryChartBars";
import SummaryChartPie from "./components/Charts/SummaryChartPie";
import SoftButton from "components/SoftButton";
import Loader from "components/Loader";
import WalletsAddedGuard from "componentsCustom/WalletsAddedGuard";
import { useDispatch, useSelector } from "react-redux"
import { getCoinsNames, parseWalletsFromRedux } from "./helpers";
import WalletError from "./components/WalletError";
import { getWallets } from "redux/actions/wallets";
import SymbolBox from "./components/SymbolBox";
import WalletNavigator from "./components/WalletNavigator";
import Icon from "@mui/material/Icon";
import "./styles.css";

const initialWallet = {
  name: "all",
  status: "success",
  coins: [],
  totalDollarValue: 0
}

function Wallet() {

  const dispatch = useDispatch()
  const walletsFromRedux = useSelector(state => state.wallets)
  const keys = useSelector(state => state.keys.data)

  const [selectedWallet, setSelectedWallet] = useState(initialWallet)
  const [cryptoCompareCoins, setCryptoCompareCoins] = useState([])

  const wallets = parseWalletsFromRedux(walletsFromRedux, keys, cryptoCompareCoins)
  const coinsNames = getCoinsNames(wallets)

  const updateWallets = () => dispatch(getWallets())
  const getWalletStatus = () => {
    if (!cryptoCompareCoins.length) return "firstLoad"
    const status = wallets.find(wallet => wallet.name === selectedWallet.name).status
    const someWalletIsLoading = wallets.some(wallet => wallet.status === "loading")
    if (selectedWallet.name === "all" && someWalletIsLoading) return "loading"
    if (selectedWallet.name === "all" && !someWalletIsLoading) return "success"
    return status
  }
  const handleSelectedWallet = e => {
    const retrievedWallet = wallets.find(wallet => wallet.name === e.target.value)
    setSelectedWallet(retrievedWallet)
  }

  useEffect(() => {
    const retrievedWallet = wallets.find(wallet => wallet.name === selectedWallet.name)
    setSelectedWallet(retrievedWallet)
  }, [walletsFromRedux])

  useEffect(() => {
    setSelectedWallet(wallets[0])
  }, [cryptoCompareCoins])

  useEffect(() => {
    axios
      .get(`https://data-api.cryptocompare.com/asset/v1/summary/list?api_key=438f04d49a4f810921257984e0321467f565fc0733637e7590c1717302a4f717`)
      .then(res => {
        const filteredCoins = res.data.Data.LIST.filter(coin => coinsNames.includes(coin.SYMBOL))
        const mappedCoins = filteredCoins.map(coin => ({ img_url: coin.LOGO_URL, symbol: coin.SYMBOL }))
        axios
          .get(`https://min-api.cryptocompare.com/data/pricemulti?fsyms=${coinsNames}&tsyms=USD,EUR&api_key=438f04d49a4f810921257984e0321467f565fc0733637e7590c1717302a4f717`)
          .then(res => {
            const parsedRes = Object.entries(res.data)
            const coinsWithPrices = mappedCoins.map(coin => {
              const coinFromList = parsedRes.find(actualCoin => actualCoin[0] === coin.symbol)
              if (coinFromList) {
                return { ...coin, dollarPrice: coinFromList[1].USD }
              }
            })
            const coinsWithoutErrors = coinsWithPrices.filter(coin => coin !== undefined)
            setCryptoCompareCoins(coinsWithoutErrors)
          })
      })
      .catch(err => console.log(err))
  }, [])

  return (
    <DashboardLayout>
      <WalletsAddedGuard>
        <SoftBox mb={2} className="title-wallet">
          <SoftBox>
            {(getWalletStatus() !== "loading" && getWalletStatus() !== "firstLoad") && (
              <SoftButton disabled={getWalletStatus() === "loading"} className="refresh-button" onClick={updateWallets}>
                <SoftTypography
                  component="span"
                  variant="h2"
                  className="refresh-icon"
                >
                  <Icon>cached</Icon>
                </SoftTypography>
              </SoftButton>
            )}
            {(getWalletStatus() === "loading" || getWalletStatus() === "firstLoad") && <Loader />}
          </SoftBox>
          <SoftTypography variant="h4" mr={2}>
            Wallet
          </SoftTypography>
          <WalletNavigator selectedWallet={selectedWallet.name} handleSelectedWallet={handleSelectedWallet} wallets={wallets} />
        </SoftBox>
        {getWalletStatus() !== "firstLoad" &&
          <SoftBox>
            {getWalletStatus() === "error" && <WalletError />}
            <Grid container spacing={2}>
              <Grid item md={6}>
                {<SummaryChartPie selectedWallet={selectedWallet} walletStatus={getWalletStatus()} />}
              </Grid>
              <Grid item md={6}>
                {<SummaryChartBars selectedWallet={selectedWallet} walletStatus={getWalletStatus()} />}
              </Grid>
              {selectedWallet.coins.map(coin => (
                <SymbolBox key={coin.symbol} coin={coin} walletStatus={getWalletStatus()} />
              ))}
            </Grid>
          </SoftBox>
        }
      </WalletsAddedGuard>
    </DashboardLayout>
  );
}

export default Wallet;
