export const ENV_TYPES = {
    DEV: "development",
    PRO: "production"
};

export const USER_TYPES = {
    TRADER: "trader",
    PORTFOLIO: "portfolio",
    CLIENT: "client",
    ADMIN: "admin"
};

export const DEPOSIT_TYPE = {
    CRYPTO: "crypto",
    IBAN: "iban"
};

export const ROUTES = {
    ROOT: "/",
    AUTH: "/auth/:authType",
    LOGIN: "/auth/login",
    FORGOT: "/auth/forgot-password",
    LOCK: "/auth/lock",
    HOME: "/home",
    TRADE: "/trade",
    ORDERS: "/orders",
    WALLET: "/wallet",
    PROFILE: "/profile",
    DEPOSIT: "/deposit",
    WITHDRAWAL: "/withdrawal",
    TRANSACTIONS: "/transactions"
};

export const ACCOUNTS = {
    WALLET: process.env.REACT_APP_WALLET,
    IBAN: process.env.REACT_APP_IBAN,
    TITULAR: process.env.REACT_APP_TITULAR_IBAN,
};

export const USER_STATUS = {
    REGISTERED: 0,
    KYC_APPROVED: 1,
    PENDING_DEPOSIT: 2,
    ACTIVE: 3,
    REGISTERED_BASTION: 1,
    PENDING_DEPOSIT_BASTION: 2,
    ACTIVE_BASTION: 3,
};

export const SYMBOLS = {
    BTC: "BTC",
    AMV: "AMV",
    BC: "BC",
};