import axios from "axios"
import { BACK_API } from "apiconfig";
import { store } from "index";

export const updateKeys = async (email, privateKey, publicKey, password, exchange) => {
    const headers = { 'token': store.getState().currentUser.data.jwtToken };
    const url = `${BACK_API}/keys`;
    const body = {
        email: email,
        privateKey: privateKey,
        publicKey: publicKey,
        password: password,
        exchange: exchange,
        isActive: true,
        userId: store.getState().currentUser.data.id
    }
    return await axios
        .post(url, body, { headers })
        .then(res => res.data)
        .catch(err => { throw err })
}

export const fetchKeys = async email => {
    const headers = { 'token': store.getState().currentUser.data.jwtToken };
    const url = `${BACK_API}/keys`;
    const params = {
        email: email
    };

    return await axios
        .get(url, { params, headers })
        .then(res => res.data)
        .catch(err => { throw err })
}

export const updateKeysActive = async (email, exchange, isActive) => {
    const headers = { 'token': store.getState().currentUser.data.jwtToken };
    const url = `${BACK_API}/keys-active`;
    const body = {
        email: email,
        exchange: exchange,
        isActive
    }
    return await axios
        .post(url, body, { headers })
        .then(res => res.data.isActive)
        .catch(err => { throw err })
}