// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftTypography from "components/SoftTypography";

export default function EditUserButton({ editClickedUser, user }) {

    return (
        <>
            <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
                <Tooltip title="Edit user" placement="top" onClick={() => editClickedUser(user)}>
                    <Icon>edit</Icon>
                </Tooltip>
            </SoftTypography>
        </>
    )
}