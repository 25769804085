import { useState } from 'react';
import { useSelector } from "react-redux"

// Soft UI Dashboard React component
import SoftInput from "components/SoftInput";

import './styles.css';

export default function WalletInput() {

  const currentUser = useSelector(state => state.currentUser.data);
  const { wallet } = currentUser;

  return (
    <>
      <SoftInput
        className="wallet__input"
        name="wallet"
        label="Wallet"
        type="text"
        placeholder="Wallet"
        value={wallet || ""}
        disabled
      />
    </>
  );
}
