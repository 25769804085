// Local components
import IbanInput from '../Inputs/Iban';
import AmountInput from '../Inputs/Amount';

import './styles.css';
import SliderAmountWithdrawal from '../Inputs/SliderAmount';

export default function Bank({ setWithdrawal, withdrawal, limit }) {

  return (
    <div className="container__bank">
      <IbanInput />
      <SliderAmountWithdrawal setWithdrawal={setWithdrawal} withdrawal={withdrawal} maxAmount={limit} />
      <AmountInput setWithdrawal={setWithdrawal} withdrawal={withdrawal} maxAmount={limit} />
    </div>
  )
}
