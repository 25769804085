// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

export default function ExchangeInput({ name, value, handle, type }) {

    return (
        <Grid item xs={12} md={6} xl={6}>
            <SoftTypography>
                {name}
            </SoftTypography>
            <SoftInput
                value={value ? value : ""}
                onChange={handle}
                label={name}
                size="small"
                placeholder={name}
                type={type}
            />
        </Grid>
    );
}