import { useState, useEffect } from 'react';
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
// @mui material components
import { Grid, Card } from "@mui/material";
// Local components
import EditTitle from "./components/Title";
import UpdateUserButton from "./components/Button/UpdateUser";
import DeleteUserButton from "./components/Button/DeleteUser";
import Input from "componentsCustom/Inputs"
// Api
import { updateUser } from 'api/users';
// Custom components
import StatusAnimations from 'componentsCustom/StatusAnimations';
// Constants
import { STATUS } from 'constants/fetchStatus';
import Transactions from './components/Transactions';
import parseDate from 'utils/parseDate';
import { USER_STATUS } from 'constants';
import { fetchUserFees } from 'api/fees';
import { IS_CLIENT_BASTION } from 'apiconfig';

const amvOptions = [
    { label: "0. Registered", value: USER_STATUS.REGISTERED },
    { label: "1. KYC approved", value: USER_STATUS.KYC_APPROVED },
    { label: "2. Deposit to be approved", value: USER_STATUS.PENDING_DEPOSIT },
    { label: "3. Active", value: USER_STATUS.ACTIVE }
]

const bastionOptions = [
    { label: "1. Registered", value: USER_STATUS.KYC_APPROVED },
    { label: "2. Deposit to be approved", value: USER_STATUS.PENDING_DEPOSIT },
    { label: "3. Active", value: USER_STATUS.ACTIVE }
]


export default function EditUserForm({ selectedUser, cancelEdit, refreshButtonAction }) {

    const [tempUserData, setTempUserData] = useState(selectedUser);
    const [formStatus, setFormStatus] = useState(STATUS.PENDING);

    useEffect(() => {
        if (tempUserData.feeCategoryId !== "") {
            fetchUserFees(tempUserData.feeCategoryId)
                .then(res => {
                    setTempUserData(prevState => {
                        return {
                            ...prevState,
                            ...res
                        }
                    })
                })
                .catch(() => alert("Fee category does not exist"))
        }
    }, [tempUserData.feeCategoryId])

    const handleSubmit = () => {
        setFormStatus(STATUS.LOADING)
        updateUser(tempUserData)
            .then(() => {
                refreshButtonAction()
                setFormStatus(STATUS.OK)
            })
            .catch(err => {
                setFormStatus(STATUS.ERROR)
                throw Error(err)
            })
    }

    const userProperties = {
        status: {
            name: "Status",
            value: tempUserData.status,
            inputType: "select",
            options: IS_CLIENT_BASTION ? bastionOptions : amvOptions,
            canEdit: true
        },
        registerDate: {
            name: "Register date",
            value: parseDate(tempUserData.registerDate),
            inputType: "date",
            canEdit: true
        },
        name: {
            name: "Name",
            value: tempUserData.name,
            inputType: "text",
            canEdit: true
        },
        surnames: {
            name: "Surnames",
            value: tempUserData.surnames,
            inputType: "text",
            canEdit: true
        },
        email: {
            name: "Email",
            value: tempUserData.email,
            inputType: "email",
            canEdit: true
        },
        wallet: {
            name: "Wallet",
            value: tempUserData.wallet,
            inputType: "text",
            canEdit: true
        },
        iban: {
            name: "IBAN",
            value: tempUserData.iban,
            inputType: "text",
            canEdit: true
        },
        kycApprovedDate: {
            name: "KYC Approved Date",
            value: parseDate(tempUserData.kycApprovedDate),
            inputType: "date",
            canEdit: true
        },
        feeCategoryId: {
            name: "Fee category Id",
            value: tempUserData.feeCategoryId,
            inputType: "number",
            canEdit: true
        },
        managementFeeFactor: {
            name: "Annual management fee factor",
            value: tempUserData.managementFeeFactor,
            inputType: "number",
            canEdit: false
        },
        performanceFeeFactor: {
            name: "Performance fee factor",
            value: tempUserData.performanceFeeFactor,
            inputType: "number",
            canEdit: false
        },
        withdrawalFeeFactor: {
            name: "Withdrawal fee factor",
            value: tempUserData.withdrawalFeeFactor,
            inputType: "number",
            canEdit: false
        },
        useWatermark: {
            name: "Use watermark",
            value: tempUserData.useWatermark,
            inputType: "boolean",
            canEdit: true
        },
    }

    return (
        <Grid container spacing={3}>
            <Grid item xl={12}>
                <SoftBox>
                    <Card>
                        <SoftBox m={3}>
                            <EditTitle title={'Personal Info'} />
                            <Grid container spacing={{ xs: 1, md: 2, xl: 3 }} mt='-1rem'>
                                {Object.entries(userProperties).map((property, i) => {
                                    const [propName, propData] = property
                                    return <Input key={i} inputData={{ ...propData, propName, updateState: setTempUserData }} />
                                })}
                            </Grid>
                            <Grid mb={3} container spacing={3} justifyContent="center" alignItems="center">
                                {formStatus === STATUS.PENDING && <UpdateUserButton handleSubmit={handleSubmit} />}
                                {formStatus === STATUS.PENDING && <DeleteUserButton userId={tempUserData.userId} userEmail={tempUserData.email} refreshButtonAction={refreshButtonAction} cancelEdit={cancelEdit} />}
                            </Grid>
                            <StatusAnimations status={formStatus} setStatusFunction={setFormStatus} afterOKAnimationAction={cancelEdit} />
                        </SoftBox>
                    </Card>
                </SoftBox>
                <SoftBox mt={3}>
                    <Card>
                        <SoftBox m={3}>
                            <EditTitle title={'Deposits and withdrawals'} />
                            <Grid container spacing={{ xs: 1, md: 2, xl: 3 }} >
                                <Transactions deposits={selectedUser.deposits} withdrawals={selectedUser.withdrawals} />
                            </Grid>
                        </SoftBox>
                    </Card>
                </SoftBox>
            </Grid>
        </Grid >
    );

}