import { useNavigate } from 'react-router-dom';

// Soft UI Dashboard React component
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

import './styles.css';
import { ROUTES } from 'constants';

export default function CancelButton({ loading }) {

  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault();
    navigate(ROUTES.PROFILE);
  }

  return (
    <div className="buttonContainer">
      <SoftButton
        variant="gradient"
        color="error"
        fullWidth
        onClick={handleClick}
        disabled={loading}
      >
        <SoftTypography
          variant="button"
          color="white"
        >
          Cancel
        </SoftTypography>
      </SoftButton>
    </div>
  );
}
