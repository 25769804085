import { fetchWallet } from "api/wallets"
import { WALLET_STATUS } from "constants/actionTypes"
import { SET_WALLET_DATA } from "constants/actionTypes"
import { getExchangeByLabel } from "utils/getExchangeByLabel"
import { store } from "index"

/***** Redux actions that end up in the store *****/

export const getWallets = () => async dispatch => {

  const keys = store.getState().keys.data
  const dispatchStatus = (wallet, status) => {
    dispatch({ type: WALLET_STATUS, payload: { status: status, wallet: wallet } })
  }

  keys.forEach(exchange => {
    const params = {
      public_key: exchange.publicKey,
      private_key: exchange.privateKey,
      password: exchange.password,
      exchange: exchange.exchange
    }
    dispatchStatus(exchange.exchange, "loading")
    fetchWallet(params)
      .then(res => {
        dispatch({ type: SET_WALLET_DATA, payload: { wallet: exchange.exchange, data: res.data.filteredDetailed } })
        dispatchStatus(exchange.exchange, "success")
      })
      .catch(() => dispatchStatus(exchange.exchange, "error"))
  })
}

export const getWallet = exchangeValue => async dispatch => {
  const exchange = getExchangeByLabel(exchangeValue)
  const keys = store.getState().keys.data
  const selectedExchageKeys = keys.find(exchange => exchange.exchange === exchangeValue)
  const params = {
    public_key: selectedExchageKeys.publicKey,
    private_key: selectedExchageKeys.privateKey,
    password: selectedExchageKeys.password,
    exchange: exchange.label
  }

  const dispatchStatus = (wallet, status) => {
    dispatch({ type: WALLET_STATUS, payload: { status: status, wallet: wallet } })
  }

  dispatchStatus(exchange.value, "loading")
  fetchWallet(params)
    .then(res => {
      dispatch({ type: SET_WALLET_DATA, payload: { wallet: exchange.value, data: res.data.filteredDetailed } })
      dispatchStatus(exchange.value, "success")
    })
    .catch(() => dispatchStatus(exchange.value, "error"))
}