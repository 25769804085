import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// Redux
import { useDispatch } from "react-redux";
import { setInformativeMessage } from 'redux/actions/informativeMessage';

// Soft UI Dashboard React component
import PageLayout from "examples/LayoutContainers/PageLayout";

// Local Components
import UserInput from "./components/Inputs/User";
import PasswordInput from "./components/Inputs/Password";
import RememberMeSwitch from "./components/Buttons/RememberMe";
import SignInButton from "./components/Buttons/SignIn";
import SignUpLink from "./components/Buttons/SignUpLink";

// Actions
import { setCurrentUser } from 'redux/actions/currentUser';
import { getUsers } from 'redux/actions/users';
import { getTokensBalances, getGlobalShifts } from 'redux/actions/charts';

// API
import { fetchLogin } from 'api/auth';

import { ROUTES, USER_TYPES, USER_STATUS } from 'constants';

import './styles.css';
import { IS_CLIENT_BASTION } from 'apiconfig';

export default function Login() {

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const sesion = useRef({
    identifier: "",
    password: "",
    rememberMe: false,
  });

  const dispatchLoginActions = async (user) => {
    const { tipo_usuario } = user;
    dispatch(setCurrentUser(user));
    tipo_usuario === USER_TYPES.ADMIN && dispatch(getUsers());
    if (tipo_usuario !== USER_TYPES.ADMIN) {
      dispatch(getTokensBalances());
      dispatch(getGlobalShifts());
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const {
      identifier,
      password
    } = sesion.current;

    if (identifier && password) {
      const response = await fetchLogin(sesion.current);
      const { success, data } = response;
      if (success) {
        const { access_token, user } = data;
        const { user_id, status, email, name, surnames, type, wallet, iban } = user;
        const parsedUser = { jwtToken: access_token, id: user_id, email, name, surname: surnames, tipo_usuario: type, wallet, iban, status };
        dispatchLoginActions(parsedUser);
        if (IS_CLIENT_BASTION) {
          switch (status) {
            case USER_STATUS.REGISTERED_BASTION:
              type === USER_TYPES.CLIENT ?
                navigate(`/auth/deposit`) :
                dispatch(setInformativeMessage({ success: false, message: "User is not active and requires confirmation from the administrator" }));
              break;
            case USER_STATUS.PENDING_DEPOSIT_BASTION:
              dispatch(setInformativeMessage({ success: true, message: "The deposit is being verified" }));
              break;
            case USER_STATUS.ACTIVE_BASTION:
              dispatchLoginActions(parsedUser);
              navigate(ROUTES.HOME);
              break;
            default:
              dispatch(setInformativeMessage({ success: false, message: "Internal server error." }));
              break;
          };
        } else {
          switch (status) {
            case USER_STATUS.REGISTERED:
              dispatch(setInformativeMessage({ success: false, message: "KYC has not been approved yet for user" }));
              break;
            case USER_STATUS.KYC_APPROVED:
              type === USER_TYPES.CLIENT ?
                navigate(`/auth/deposit`) :
                dispatch(setInformativeMessage({ success: false, message: "User is not active and requires confirmation from the administrator" }));
              break;
            case USER_STATUS.PENDING_DEPOSIT:
              dispatch(setInformativeMessage({ success: true, message: "The deposit is being verified" }));
              break;
            case USER_STATUS.ACTIVE:
              dispatchLoginActions(parsedUser);
              navigate(ROUTES.HOME);
              break;
            default:
              dispatch(setInformativeMessage({ success: false, message: "Internal server error." }));
              break;
          };
        }
      } else {
        dispatch(setInformativeMessage({ success, message: data }));
      }
    } else {
      dispatch(setInformativeMessage({ success: false, message: "Email and Password are required." }));
    }
    setLoading(false);
  };

  return (
    <PageLayout>
      <div className="authPage">
        <div className="wrapper">
          <div className="formContainer">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <UserInput sesion={sesion} />
                <PasswordInput sesion={sesion} />
                <RememberMeSwitch sesion={sesion} />
                <SignInButton loading={loading} />
              </div>
            </form>
          </div>
          <SignUpLink />
        </div>
      </div>
    </PageLayout>
  );
}
