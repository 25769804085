// Soft UI Dashboard React components
import SoftButton from "components/SoftButton";

// @mui material components
import { Grid } from "@mui/material";
import ArrowBack from '@mui/icons-material/ArrowBack';

export default function CancelButton({ cancelEdit }) {

    return (
        <Grid item>
            <SoftButton
                onClick={cancelEdit}
                variant="gradient"
                color="info"
                fullWidth
            >
                <ArrowBack />&nbsp;
            </SoftButton>
        </Grid>
    );

}