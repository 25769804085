import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux"
import { configureStore } from "@reduxjs/toolkit";
import reducers from "redux/reducers"
import App from "App";
import { SoftUIControllerProvider } from "context";
import sessionSaverMiddleware from './utils/sessionSaverMiddleware';
import { runSentry } from "sentry";

// We use Sentry to receive error warnings
runSentry()

// Load state from session storage (if available)
const savedState = sessionStorage.getItem('reduxState');
const initialState = savedState ? JSON.parse(savedState) : {};

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sessionSaverMiddleware),
  preloadedState: initialState, // Set the preloadedState with the initial state
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <SoftUIControllerProvider>
        <App />
      </SoftUIControllerProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
