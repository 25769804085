export default function sortOrdersByTimestamp(orders) {
    return orders.sort((a, b) => {
        const timestampA = new Date(timestampToMs(a.timestamp));
        const timestampB = new Date(timestampToMs(b.timestamp));
        return timestampB - timestampA;
    });
}

const timestampToMs = timestamp => {
    const parsedTimestamp = parseInt(timestamp);
    const length = timestamp.toString().length;
    // Check if the timestamp is in seconds or milliseconds
    if (length === 10) {
        // Convert seconds to milliseconds
        return parsedTimestamp * 1000;
    } else if (length === 13) {
        // Milliseconds timestamp
        return parsedTimestamp;
    }
}