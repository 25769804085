import { BACK_API_AMV } from "apiconfig";
import { store } from "index";
import axios from "axios"

export async function fetchUserFees(categoryId) {
    const url = `${BACK_API_AMV}/fees/category/${categoryId}`;
    const headers = { 'Authorization': `Bearer ${store.getState().currentUser.data.jwtToken}` };
    return await axios
        .get(url, { headers })
        .then(res => {
            return {
                managementFeeFactor: res.data.management_factor,
                performanceFeeFactor: res.data.performance_factor,
                withdrawalFeeFactor: res.data.withdrawal_factor
            }
        });
};