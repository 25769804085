import { useState } from 'react';

// Redux
import { useDispatch } from "react-redux";
import { setInformativeMessage } from 'redux/actions/informativeMessage';

// Soft UI Dashboard React components
import SoftTypography from 'components/SoftTypography';

// @mui material components
import Stack from '@mui/material/Stack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// Local components
import IbanInput from '../Inputs/Iban';
import AmountInput from '../Inputs/Amount';

import { ACCOUNTS } from 'constants';

import './styles.css';

export default function Bank({ deposit, setDeposit }) {
  const dispatch = useDispatch();
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handleOnCLick = () => {
    navigator.clipboard.writeText(ACCOUNTS.IBAN);
    dispatch(setInformativeMessage({ success: true, message: 'Copied to Clipboard!' }));
  };

  return (
    <div className="container__bank">
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={4} marginBottom="20px">
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ cursor: 'pointer' }}
          onClick={handleOnCLick}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <SoftTypography
            variant="button"
            sx={{ fontSize: 13.5 }}
          >
            {isHovering ? <ContentCopyIcon /> : '\u00A0'}
          </SoftTypography>
          <SoftTypography className="typography" variant="button">
            {`IBAN: ${ACCOUNTS.IBAN}`}
          </SoftTypography>
          {ACCOUNTS.TITULAR &&
            <SoftTypography className="typography" variant="button">
              {`TITULAR: ${ACCOUNTS.TITULAR}`}
            </SoftTypography>
          }
        </Stack>
      </Stack>
      <IbanInput />
      <AmountInput deposit={deposit} setDeposit={setDeposit} />
    </div>
  )
}
