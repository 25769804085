import axios from "axios";
import { BACK_API_PANICBT, BOT_CONTAINER_NAME } from "apiconfig";
import { store } from "index";

export async function fetchStopBot() {
    const url = `${BACK_API_PANICBT}/stop-container-by-name/${BOT_CONTAINER_NAME}`;
    const headers = { 'Authorization': `Bearer ${store.getState().currentUser.data.jwtToken}` };
    return await axios
        .get(url, { headers })
        .then(({ data }) => {
            return { data, success: true }
        })
        .catch((err) => {
            return { detail: { ...err }, success: false }
        });
};