// @mui material components
import { Grid } from "@mui/material";
import Icon from "@mui/material/Icon";
// Soft UI Dashboard React components
import Lottie from "lottie-react";
import SoftButton from "components/SoftButton";
import success from "assets/animation/success.json";
import failed from "assets/animation/failed.json"
import Loader from "components/Loader";
import '../../index.css';

export default function CancelButton({ trade, deleteStatus, handleCancelOrder, handleCompleted }) {
    return (
        trade.status === "open" &&
        <Grid item lg={1}>
            {!(deleteStatus.id === trade.id && (deleteStatus.status === "loading" || deleteStatus.status === "error")) && <SoftButton disabled={deleteStatus.id === trade.id} onClick={() => handleCancelOrder(trade)} className="crypto-close-order" iconOnly variant="gradient" size="small" color="error">
                <Icon>close</Icon>
            </SoftButton>}
            {(deleteStatus.id === trade.id && deleteStatus.status === "success") && <center>
                <Lottie loop={false} style={{ display: "inline-block", padding: "0px !important", margin: "0px !important", width: 54, height: "auto" }} onComplete={handleCompleted} animationData={success} />
            </center>
            }
            {(deleteStatus.id === trade.id && deleteStatus.status === "error") && <center>
                <Lottie loop={false} style={{ display: "inline-block", padding: "0px !important", margin: "0px !important", width: 54, height: "auto" }} onComplete={handleCompleted} animationData={failed} />
            </center>
            }
            {(deleteStatus.id === trade.id && deleteStatus.status === "loading") && <Loader />}
        </Grid>
    )
}