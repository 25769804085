export const timestampToDateTime = timestamp => {
    const parsedTimestamp = parseInt(timestamp);
    const length = timestamp.toString().length;

    // Check if the timestamp is in seconds or milliseconds
    if (length === 10) {
        // Convert seconds to milliseconds
        return formatTimestamp(new Date(parsedTimestamp * 1000));
    } else if (length === 13) {
        // Milliseconds timestamp
        return formatTimestamp(new Date(parsedTimestamp));
    } else {
        return "Invalid timestamp format";
    }
}

function formatTimestamp(date) {
    const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
    };

    return date.toLocaleString(undefined, options);
}