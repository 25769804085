import { useState } from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";

// @mui material components
import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Local components
import ExchangeIcon from "../ExchangeIcon";
import ExchangeLabel from "../ExchangeLabel";
import ExchangeSwitch from "../ExchangeSwitch";
import ExchangeButton from "../ExchangeButton";
import KeyManager from "../KeyManager";
import { toggleKeysActive } from "redux/actions/keys";
import { STATUS } from "constants/fetchStatus";
import ReduxStatusAnimations from "componentsCustom/ReduxStatusAnimations";
import "../../index.css";

export default function Exchange({ exchange }) {

    const { label, img, value } = exchange;
    const keysData = useSelector(state => state.keys.data.find(data => data.exchange === value))
    const active = keysData?.isActive || false
    const status = keysData?.status || STATUS.PENDING

    const dispatch = useDispatch()
    const currentUser = useSelector(state => state.currentUser.data);

    const [publicKey, setPublicKey] = useState(keysData?.publicKey || "");
    const [privateKey, setPrivateKey] = useState(keysData?.privateKey || "");
    const [password, setPassword] = useState(keysData?.password || "");
    const [opened, setOpened] = useState(false);

    const handleOpen = () => setOpened(prevOpened => !prevOpened);

    const submitActive = () => {
        if (publicKey && privateKey) {
            dispatch(toggleKeysActive(currentUser.email, exchange.value, !active))
        } else (
            alert("You need to add keys")
        )
    }

    return (
        <SoftBox mt={1} mb={4}>
            <Card className="exchange-card">
                <Grid mt={2} px={3} container>
                    <Grid item xs={4} md={3}>
                        <ExchangeIcon img={img} />
                        <ExchangeLabel label={label} />
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <ExchangeSwitch active={active} activeLoading={status === STATUS.LOADING} submitActive={submitActive} />
                    </Grid>
                    <Grid item xs={4} md={5} />
                    <Grid item xs={4} md={2}>
                        <ExchangeButton handleOpen={handleOpen} />
                    </Grid>
                </Grid>
                {opened && <KeyManager exchange={{ ...exchange, publicKey, privateKey, password, setPublicKey, setPrivateKey, setPassword, status }} />}
                <ReduxStatusAnimations status={status} />
            </Card>
        </SoftBox>

    );
};