import { SYMBOLS } from "constants";
import { getLastThreeMonths } from "./getLastThreeMonths";
import { IS_CLIENT_BASTION } from "apiconfig";

export const parseTokenShifts = (data, symbolToken, globalShifts) => {

    const dataset = {
        label: symbolToken,
        color: "warning",
        data: [],
    };

    data.forEach(({ token, shifts }) => {
        if (token.symbol === symbolToken) {
            const shiftsByDay = {};
            const lastThreeMonths = getLastThreeMonths(globalShifts.length < 90 ? globalShifts.length : 90);

            shifts.forEach((shift) => {
                const date = new Date(shift.timestamp);
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
                const day = date.getDate();
                const key = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;

                if (!shiftsByDay[key]) {
                    shiftsByDay[key] = [];
                }

                shiftsByDay[key].push(shift.shift);
            });

            lastThreeMonths.forEach(date => {
                if (!shiftsByDay[date]) {
                    dataset.data.push(null);
                } else {
                    const totalShift = shiftsByDay[date].reduce((acc, shift) => acc * (1 + shift / 100), 1);
                    dataset.data.push(100 * (totalShift - 1));
                };
            });
        };
    });

    return dataset;
};

export const parseGlobalShifts = (data) => {

    const dataset = {
        label: IS_CLIENT_BASTION ? SYMBOLS.BC : SYMBOLS.AMV,
        color: "info",
        data: [],
    };

    const shiftsByDay = {};
    const lastThreeMonths = getLastThreeMonths(data.length < 90 ? data.length : 90);

    data.forEach(({ shift, timestamp }) => {

        const date = new Date(timestamp);
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const day = date.getDate();
        const key = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;

        if (!shiftsByDay[key]) {
            shiftsByDay[key] = [];
        }

        shiftsByDay[key].push(shift);
    });

    lastThreeMonths.forEach(date => {
        if (!shiftsByDay[date]) {
            dataset.data.push(null);
        } else {
            const totalShift = shiftsByDay[date].reduce((acc, shift) => acc * (1 + shift / 100), 1);
            dataset.data.push(100 * (totalShift - 1));
        };
    });

    return dataset;
};

export const getLineChartData = (globalShifts, tokens, symbolToken) => {

    const response = {
        labels: getLastThreeMonths(globalShifts.length < 90 ? globalShifts.length : 90),
        datasets: [parseTokenShifts(tokens, symbolToken, globalShifts), parseGlobalShifts(globalShifts)]
    }

    return response;
};