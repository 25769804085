/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Professional Components
import ProfessionalAuthPage from 'layouts/Professional/AuthPage';
import ProfessionalDashboard from 'layouts/Professional/Dashboard';

// Common Components
import RootUrl from 'layouts/Common/RootUrl';
import PrivateRoute from 'componentsCustom/PrivateRoute';
import Trade from 'layouts/Common/Trade';
import Orders from 'layouts/Common/Orders';
import UserProfile from 'layouts/Common/UserProfile';
import Wallet from 'layouts/Common/Wallet';
import Withdrawal from 'layouts/Common/Withdrawal';
import Deposit from 'layouts/Common/Deposit';
import Transactions from 'layouts/Professional/Transactions';

// Material UI Components
import { Home, AnalyticsOutlined, AccountCircle, Money, Article, SwapVert } from "@mui/icons-material";

// Utils
import filterRoutes from 'utils/filterRoutes';
import { ROUTES } from 'constants';

const routes = [
  {
    type: "route",
    name: "Profile",
    key: "profile",
    route: ROUTES.ROOT,
    icon: <AccountCircle size="12px" />,
    component: <PrivateRoute component={<RootUrl />} />,
    noCollapse: true,
  },
  {
    type: "route",
    name: "Login",
    key: "login",
    route: ROUTES.AUTH,
    component: <ProfessionalAuthPage />
  },
  {
    type: "route",
    name: "Deposit",
    key: "deposit",
    route: ROUTES.DEPOSIT,
    component: <PrivateRoute component={<Deposit />} />,
  },
  {
    type: "route",
    name: "Withdrawal",
    key: "withdrawal",
    route: ROUTES.WITHDRAWAL,
    component: <PrivateRoute component={<Withdrawal />} />,
  },
  {
    type: "collapse",
    name: "Home",
    key: "home",
    route: ROUTES.HOME,
    icon: <Home size="12px" />,
    component: <PrivateRoute component={<ProfessionalDashboard />} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Transactions",
    key: "transactions",
    route: ROUTES.TRANSACTIONS,
    icon: <SwapVert size="12px" />,
    component: <PrivateRoute component={<Transactions />} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Trade",
    key: "trade",
    route: ROUTES.TRADE,
    icon: <AnalyticsOutlined size="12px" color="white" />,
    component: <PrivateRoute component={<Trade />} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Orders",
    key: "orders",
    route: ROUTES.ORDERS,
    icon: <Article size="12px" />,
    component: <PrivateRoute component={<Orders />} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Wallet",
    key: "wallet",
    route: ROUTES.WALLET,
    icon: <Money size="12px" />,
    component: <PrivateRoute component={<Wallet />} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: ROUTES.PROFILE,
    icon: <AccountCircle size="12px" />,
    component: <PrivateRoute component={<UserProfile />} />,
    noCollapse: true,
  }
];

export default function routesFromUser(userType) {
  return filterRoutes(routes, userType)
}
