// @mui material components
import { Grid } from "@mui/material";

// Soft UI Dashboard React components
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";

export default function TextInput({ inputData }) {
    const { name, value, canEdit, inputType, propName, updateState } = inputData;

    const handleInputChange = ({ target }) => {
        updateState(prevState => {
            return { ...prevState, [propName]: target.value }
        });
    }

    return (
        <Grid item xs={12} md={6} xl={4} >
            <SoftTypography variant="button" fontSize="0.75rem" >
                {name}
            </SoftTypography>
            <SoftInput
                type={inputType}
                placeholder={name}
                name={propName}
                value={value || ''}
                disabled={!canEdit}
                onChange={handleInputChange}
            />
        </Grid>
    );

}