// react-redux
import { useSelector } from 'react-redux';

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Custom components
import PerformanceDashboard from "../components/Charts/PerformanceDashboard";
import { STATUS } from 'constants/fetchStatus';
import Loader from 'components/Loader';


export default function ClientDashboard() {

    const { status } = useSelector(state => state.charts);

    return (
        <DashboardLayout>
            <SoftBox my={3}>
                <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} sx={{ lineHeight: 0 }}>
                            <SoftTypography variant="h5">Portfolio performance</SoftTypography>
                        </Grid>
                    </Grid>
                </SoftBox>
                <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            {status === STATUS.LOADING && <Loader />}
                            {status === STATUS.OK && <PerformanceDashboard />}
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>
        </DashboardLayout>
    );
}
