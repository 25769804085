
import { useState } from "react"
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useSelector, useDispatch } from "react-redux"
import UsersTable from "./Components/UsersTable";
import { getUsers } from "redux/actions/users";
import RefreshButton from "layouts/Common/Orders/components/RefreshButton";
import { STATUS } from "constants/fetchStatus";
import EditUserForm from "./Components/EditUserForm";
import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import CancelButton from "./Components/EditUserForm/components/Button/Cancel";
import SoftTypography from "components/SoftTypography";

export default function AdminPanel() {
    const users = useSelector(state => state.users)
    const dispatch = useDispatch()
    const refreshButtonAction = () => {
        dispatch(getUsers())
    }
    const [isEditing, setIsEditing] = useState(false)
    const [selectedUser, setSelectedUser] = useState({})

    const cancelEdit = () => {
        setIsEditing(false)
        setSelectedUser({})
    }
    const editClickedUser = user => {
        setSelectedUser(user)
        setIsEditing(true)
    }

    return (
        <DashboardLayout>
            <Grid container justifyContent="flex-start" alignItems="center" mb={3}>
                <SoftBox ml={2}>
                    {isEditing ?
                        <CancelButton cancelEdit={cancelEdit} /> :
                        <RefreshButton isLoading={users.state === STATUS.LOADING} action={refreshButtonAction} />
                    }
                </SoftBox>
                <SoftBox ml={2}>
                    <SoftTypography variant="h3">
                        {isEditing ? `Editing user: ${selectedUser.email}` : "Admin panel"}
                    </SoftTypography>
                </SoftBox>
            </Grid>
            {isEditing ? (
                <EditUserForm selectedUser={selectedUser} cancelEdit={cancelEdit} refreshButtonAction={refreshButtonAction} />
            ) : (
                <UsersTable users={users} editClickedUser={editClickedUser} />
            )}
        </DashboardLayout>
    );
}

