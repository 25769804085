import { fetchUsers } from "api/users";
import { SET_USERS_STATUS, FETCH_USERS } from "constants/actionTypes";
import { STATUS } from "constants/fetchStatus";

/***** Redux actions that end up in the store *****/
export const getUsers = () => async dispatch => {
    try {
        dispatch({ type: SET_USERS_STATUS, payload: STATUS.LOADING });
        const data = await fetchUsers();
        dispatch({ type: FETCH_USERS, payload: data });
        dispatch({ type: SET_USERS_STATUS, payload: STATUS.OK });
    } catch (error) {
        dispatch({ type: SET_USERS_STATUS, payload: STATUS.ERROR });
    };
};