import { useState } from 'react';

// @mui material components
import { Grid } from "@mui/material";

// Soft UI Dashboard React components
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";

export default function SelectInput({ inputData }) {

    const { name, value, canEdit, options, propName, updateState } = inputData

    const handleInputChange = (event) => {
        updateState(prevState => {
            return { ...prevState, [propName]: event.value }
        });
    }
  
    return (
        <Grid item xs={12} md={6} xl={4}>
            <SoftTypography variant="button" fontSize="0.75rem" >
                {name}
            </SoftTypography>
            <SoftSelect
                options={options}
                defaultValue={[...options].find(option => option.value === value)}
                menuPosition="fixed"
                disabled={!canEdit}
                onChange={handleInputChange}
            />
        </Grid>
    );
}
