// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// Data
import { useState } from "react";
// @mui material components
import { Grid } from "@mui/material";
import FloatingOrder from "./FloatingOrder";
import ExchangeSelector from "./components/ExchangeSelector";
import SymbolSelector from "./components/SymbolSelector";
import TradeButton from "./components/TradeButton";
import { isValidSymbol } from "utils/isValidSymbol";
import WalletsAddedGuard from "componentsCustom/WalletsAddedGuard";
import './index.css';

function Trade() {

  const [selectedExchange, setSelectedExchange] = useState("")
  const [selectedSymbol, setSelectedSymbol] = useState("")
  const [chartOpened, setChartOpened] = useState(false)
  const allowedSymbols = ["BTC/USDC"]
  const readyToTrade = selectedExchange && isValidSymbol(selectedSymbol, allowedSymbols)

  return (
    <DashboardLayout classname="tradingview-iframe">
      <WalletsAddedGuard>
        {!chartOpened &&
          <SoftBox>
            <Grid container spacing={2} alignItems="flex-end">
              <ExchangeSelector
                selectedExchange={selectedExchange}
                setSelectedExchange={setSelectedExchange}
              />
              <SymbolSelector
                symbols={allowedSymbols}
                selectedExchange={selectedExchange}
                selectedSymbol={selectedSymbol}
                setSelectedSymbol={setSelectedSymbol}
              />
              <TradeButton readyToTrade={readyToTrade} setChartOpened={setChartOpened} />
            </Grid>
          </SoftBox>
        }
        {chartOpened &&
          <FloatingOrder
            setChartOpened={setChartOpened}
            selectedExchange={selectedExchange}
            selectedSymbol={selectedSymbol}
          />
        }
      </WalletsAddedGuard>
    </DashboardLayout>
  );
}

export default Trade;
