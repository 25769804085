// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";

// @mui material components
import { Grid, Button } from "@mui/material";

// @mui icons
import EditIcon from '@mui/icons-material/Edit';;

export default function InformationTitle({ editLayout, setEditLayout }) {

    const handleClick = () => {
        setEditLayout(!editLayout)
    }

    return (
        <Grid container>
            <Grid item xs={11} >
                <SoftTypography variant="h4" sx={{ display: "inline-block" }}>
                    Profile Information
                </SoftTypography>
            </Grid>
            {/* <Grid item xs={1} >
                <Button onClick={handleClick}>
                    <EditIcon />
                </Button>
            </Grid> */}
        </Grid>
    );

}