// @mui material components
import { useState } from "react";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import DataTable from "examples/Tables/DataTable";
import { cancelOrder } from "api/orders";
import Side from "../TableItems/Side";
import Amount from "../TableItems/Amount";
import Cost from "../TableItems/Cost";
import Price from "../TableItems/Price";
import Exchange from "../TableItems/Exchange";
import Type from "../TableItems/Type";
import Date from "../TableItems/Date";
import CancelButton from "../TableItems/CancelButton";
import CryptoPair from "layouts/Common/CryptoPair";
import '../../index.css';

export default function TradesTable({ trades, getInitialTrades, selectedOrderType }) {

    const initialDeleteStatus = { id: "", status: "ready" }
    const [deleteStatus, setDeleteStatus] = useState(initialDeleteStatus)
    const updateDeleteStatus = (trade, newStatus) => {
        setDeleteStatus({ id: trade.id, status: newStatus })
    }

    const handleCancelOrder = async trade => {
        updateDeleteStatus(trade, "loading")
        cancelOrder(trade)
            .then(() => {
                updateDeleteStatus(trade, "success")
                getInitialTrades()
            })
            .catch(() => updateDeleteStatus(trade, "error"))
    }

    const handleCompleted = () => {
        setDeleteStatus(initialDeleteStatus)
    }

    const getTableColumns = () => {
        const initialArray = [
            { Header: "symbol", accessor: "symbol", width: "10%" },
            { Header: "side", accessor: "side", width: "6%" },
            { Header: "amount", accessor: "amount", width: "10%" },
            { Header: "price", accessor: "price", width: "12%" },
            { Header: "cost", accessor: "cost", width: "12%" },
            { Header: "exchange", accessor: "exchange", width: "8%" },
            { Header: "type", accessor: "type", width: "8%" },
            { Header: "date", accessor: "date", width: "12%" }
        ]
        if (selectedOrderType === "open") initialArray.push({ Header: "cancel", accessor: "cancel", width: "10%" })
        return initialArray
    }

    const getTableRows = () => {
        return trades.map(trade => {
            return {
                symbol: <CryptoPair symbol={trade.symbol} />,
                side: <Side side={trade.side} />,
                amount: <Amount trade={trade} />,
                price: <Price trade={trade} />,
                cost: <Cost trade={trade} />,
                exchange: <Exchange trade={trade} />,
                type: <Type trade={trade} />,
                date: <Date trade={trade} />,
                cancel: <CancelButton trade={trade} deleteStatus={deleteStatus} handleCancelOrder={handleCancelOrder} handleCompleted={handleCompleted} />
            }
        })
    }

    const tableContent = {
        columns: getTableColumns(),
        rows: getTableRows()
    }

    return (
        <SoftBox mt={3}>
            {trades.length > 0 && <DataTable /* activate sort and search when possible*/ isSorted={false} canSearch={false} table={tableContent} />}
        </SoftBox>
    )
}