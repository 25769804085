// @mui material components
import { Grid } from '@mui/material';

// Soft UI Dashboard React components
import SoftTypography from 'components/SoftTypography';

export default function UserField({ profileInfo, name, value }) {
    const info = profileInfo.current[value]
    return (
        <Grid
            container
            alignItems="center"
            mb='0.8rem'
        >
            <Grid item xs md xl>
                <SoftTypography variant="h6">
                    {name}:
                </SoftTypography>
            </Grid>
            <Grid item xs={6} md={6} xl={6} pb='0.4rem'>
                <SoftTypography variant="caption" fontWeight="regular">
                    {info || '\u2002'}
                </SoftTypography>
            </Grid>
        </Grid>
    );

}