// @mui material components
import { Grid } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { parseActiveExchangesWithDataFromKeys } from "utils/parseActiveExchangesWithDataFromKeys.js";
import { useSelector } from "react-redux"
import '../../index.css';


export default function ExchangeSelector({ selectedExchange, setSelectedExchange }) {

    const keys = useSelector(state => state.keys.data)
    const [activeExchanges, setActiveExchanges] = useState([])

    const loadExchanges = () => {
        if (keys) {
            const exchanges = parseActiveExchangesWithDataFromKeys(keys)
            setActiveExchanges(exchanges)
            setSelectedExchange(exchanges[0]?.label)
        }
    }

    useEffect(() => {
        loadExchanges()
    }, [keys])
    
    return (
        <Grid item lg={2}>
            <SoftTypography mb={1} variant="h4">
                Exchange
            </SoftTypography>
            <Autocomplete
                disablePortal
                value={selectedExchange}
                onInputChange={(e, val) => {
                    setSelectedExchange(val);
                }}
                options={activeExchanges.map(exchange => exchange.cryptoCompareLabel)}
                renderInput={params => <TextField {...params} />}
            />
        </Grid>
    );
}
