// @mui material components
import { Grid } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';

// Soft UI Dashboard React components
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';

export default function ProfileInfoButton({ isExchangeOpen, setIsExchangeOpen }) {

    const handleClick = () => {
        setIsExchangeOpen(!isExchangeOpen);
    }

    return (
        <Grid item xs={12} md={4} xl={4} justifyContent="center">
            <SoftButton variant="gradient" color="info" fullWidth onClick={handleClick}>
                <PersonIcon />&nbsp;
                <SoftTypography variant="button" color="white">
                    Profile
                </SoftTypography>
            </SoftButton>
        </Grid>
    );

}