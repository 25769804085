import { v4 as uuidv4 } from 'uuid';

export const profileData = [
    {
        id: uuidv4(),
        name: 'Name',
        value: 'name',
        type: "text",
        required: true,
    },
    {
        id: uuidv4(),
        name: 'Surname',
        value: 'surname',
        type: "text",
        required: true,
    },
    {
        id: uuidv4(),
        name: 'Email',
        value: 'email',
        type: "email",
        required: true,
    },
    {
        id: uuidv4(),
        name: 'Deposit Type',
        value: 'depositype',
        type: "text",
        disabled: true,
        required: true,
    },
];
