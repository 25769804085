import { combineReducers } from "redux";

import wallets from "./wallets";
import orders from "./orders";
import currentUser from "./currentUser";
import users from "./users";
import keys from "./keys"
import informativeMessage from "./informativeMessage";
import charts from "./charts";

export default combineReducers({
    wallets,
    orders,
    currentUser,
    users,
    keys,
    informativeMessage,
    charts
});