import { useState } from 'react';

// Soft UI Dashboard React component
import SoftInput from "components/SoftInput";
import SoftTypography from 'components/SoftTypography';
import InputErrors from 'components/InputErrors';

import './styles.css';

export default function ConfirmPasswordInput({ data, showPassword }) {

  const { password, passwordConfirmation } = data.current;
  const [inputError, setInputError] = useState([]);

  const handleChange = (event) => {
    const { target: { value } } = event;
    data.current.passwordConfirmation = value;
    if (value.length === 0) {
      setInputError(["Confirm password is required."]);
    } else if (value !== password) {
      setInputError(["Passwords do not match."]);
    } else {
      setInputError([]);
    };
  };

  return (
    <div className="container">
      <SoftTypography>
        Confirm password
      </SoftTypography>
      <SoftInput
        className="confirm__password__input"
        label="Confirm Password"
        name="confirmPassword"
        onChange={handleChange}
        type={showPassword ? "text" : "password"}
        validations={{ required: true }}
        value={passwordConfirmation}
      />
      <InputErrors
        className="errorsContainer"
        errors={inputError}
      />
    </div>
  );
}
