const form = {
  formId: "new-user-form",
  formField: {
    firstName: {
      name: "firstName",
      label: "first name",
      type: "text",
      placeholder: "eg. Michael",
      errorMsg: "First name is required.",
    },
    lastName: {
      name: "lastName",
      label: "last name",
      type: "text",
      placeholder: "eg. Prior",
      errorMsg: "Last name is required.",
    },
    email: {
      name: "email",
      label: "email address",
      type: "email",
      placeholder: "eg. michael@gmail.io",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },
    password: {
      name: "password",
      label: "password",
      type: "password",
      placeholder: "******",
      errorMsg: "Password is required.",
      invalidMsg: "Your password should be more than 8 characters.",
    },
    repeatPassword: {
      name: "repeatPassword",
      label: "repeat password",
      type: "password",
      placeholder: "******",
      errorMsg: "Password is required.",
      invalidMsg: "Your password doesn't match.",
    },
    question1: {
      name: "question1",
      label: "question 1",
      type: "text",
      errorMsg: "Question 1 is required.",
    },
    question2: {
      name: "question2",
      label: "question 2",
      type: "text",
      errorMsg: "Question 2 is required.",
    },
    question3: {
      name: "question3",
      label: "question 3",
      type: "text",
      errorMsg: "Question 3 is required.",
    },
    depositType: {
      name: "depositType",
      label: "deposit type",
      type: "text",
      errorMsg: "Deposit type is required.",
    },
    account: {
      name: "account",
      errorMsg: "Account is required.",
    },
    wallet: {
      name: "wallet",
      label: "Wallet account",
      placeholder: "0x12345...",
      errorMsg: "Wallet account is required.",
    },
    iban: {
      name: "iban",
      label: "IBAN account",
      placeholder: "ABC123...",
      errorMsg: "IBAN account is required.",
    },
  },
};

export default form;
