import { useState } from 'react';

// @mui material components
import { Grid } from "@mui/material";

// Soft UI Dashboard React components
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";

export default function TypeInput({ name, type, value, disabled = false, profileInfo }) {

    const [tempValue, setTempValue] = useState(profileInfo.current[value]);

    const handleInputChange = ({ target }) => {
        setTempValue(target.value);
        profileInfo.current[value] = target.value
    }

    return (
        <Grid item xs={12} md={6} xl={6} >
            <SoftTypography variant="button" fontSize="0.75rem" >
                {name}
            </SoftTypography>
            <SoftInput
                type={type}
                placeholder={name}
                name={value}
                value={tempValue || ''}
                disabled={disabled}
                onChange={handleInputChange}
            />
        </Grid>
    );

}