import { useState, useEffect } from "react";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import DataTable from "examples/Tables/DataTable";
import SoftTypography from "components/SoftTypography";
import { fetchUserTransactions } from "api/transaction";
import { useSelector } from "react-redux"
import { STATUS } from "constants/fetchStatus";
import Loader from "components/Loader";
import parseDate from "utils/parseDate";

const parseTransactionStatus = status => {
    switch (status) {
        case null:
            return "Pending"
        case true:
            return "Approved"
        case false:
            return "Denied"
    }
}

export default function TransactionsTable() {

    const userId = useSelector(state => state.currentUser.data.id);
    const [transactions, setTransactions] = useState([])
    const [status, setStatus] = useState(STATUS.LOADING)

    const getTransactions = async () => {
        try {
            setStatus(STATUS.LOADING)
            const fetchedTransactions = await fetchUserTransactions(userId)
            setTransactions(fetchedTransactions)
            setStatus(STATUS.OK)
        } catch (error) {
            setStatus(STATUS.ERROR)
        }
    }

    useEffect(() => {
        getTransactions()
    }, [])


    const getTableColumns = () => {
        const initialArray = [
            { Header: "transaction", accessor: "transaction", width: "10%" },
            { Header: "Creation date", accessor: "creationdate", width: "6%" },
            { Header: "type", accessor: "type", width: "6%" },
            { Header: "amount", accessor: "amount", width: "6%" },
            { Header: "approved", accessor: "approved", width: "6%" },
            { Header: "approvedDate", accessor: "approvedDate", width: "6%" },
        ]
        return initialArray
    }

    const getTableRows = () => {
        return transactions.map(transaction => {
            return {
                transaction: transaction.transaction,
                creationdate: parseDate(transaction.timestamp),
                type: transaction.type,
                amount: transaction.amount,
                approved: parseTransactionStatus(transaction.approved),
                approvedDate: parseDate(transaction.approvedDate) || "-"
            }
        })
    }

    const tableContent = {
        columns: getTableColumns(),
        rows: getTableRows()
    }

    return (
        <SoftBox>
            {(transactions.length === 0 && status === STATUS.OK) && <SoftTypography>No transactions yet!</SoftTypography>}
            {status === STATUS.LOADING && <Loader />}
            {status === STATUS.ERROR && "ERROR"}
            {(
                status !== STATUS.LOADING &&
                status !== STATUS.ERROR &&
                transactions.length > 0
            ) &&
                <DataTable isSorted={false} canSearch={false} table={tableContent} />
            }
        </SoftBox>
    )
}