// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import { Stack } from '@mui/material';
import { ROUTES } from "constants";

export default function NoWalletsWarning() {
    return (
        <Stack spacing={2} sx={{ margin: 5 }} justifyContent="center" alignItems="center" >
            <SoftTypography className="amount-card" ownerState={{ verticalAlign: "baseline" }}>
                You need to activate an exchange!
            </SoftTypography>
            <SoftButton href={ROUTES.PROFILE} style={{ marginTop: "20px", align: 'center', maxWidth: "128px" }} variant="gradient" color="info" fullWidth>
                <SoftTypography variant="button" color="white">
                    Activate
                </SoftTypography>
            </SoftButton>
        </Stack>
    )
}