// @mui material components
import Card from "@mui/material/Card";
import { Grid } from "@mui/material";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import ReactModal from 'react-modal-resizable-draggable';
import Icon from "@mui/material/Icon";
import { useState } from "react";
import SoftTypography from "components/SoftTypography";
import axios from "axios";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { useDispatch, useSelector } from "react-redux"
import OpenInFull from '@mui/icons-material/OpenInFull';
import Lottie from "lottie-react";
import success from "assets/animation/success.json";
import failed from "assets/animation/failed.json"
import { BACK_API } from "apiconfig";
import Loader from "components/Loader";
import { getWallet } from "redux/actions/wallets";
import { getOrders } from "redux/actions/orders";
import { exchangesData } from "data/exchanges";
import './index.css';


function FloatingOrder({ setChartOpened, selectedExchange, selectedSymbol }) {

    const [modalIsOpen, modalSetOpen] = useState(true);
    const [minimizedModal, setMinimized] = useState();
    const dispatch = useDispatch()

    const modal = () => {
        modalSetOpen(current => !current);
    };

    const minimize = () => {
        setMinimized(current => !current);
    }

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    const [buy, setBuy] = useState(true);
    const [sell, setSell] = useState(false);

    const [limitOrder, setLimitOrder] = useState(true);
    const [marketOrder, setMarketOrder] = useState(false);

    const [orderType, setOrderType] = useState("limit");
    const [side, setSide] = useState("buy");

    const [orderStatus, setOrderStatus] = useState("ready");

    const handleClickSideBuy = () => {
        setBuy(true);
        setSell(false);
        setSide("buy");
    }

    const handleClickSideSell = () => {
        setSell(true);
        setBuy(false);
        setSide("sell");
    }

    const handleClickLimitOrder = () => {
        setLimitOrder(true);
        setMarketOrder(false);
        setOrderType("limit");
    }

    const handleClickMarketOrder = () => {
        setMarketOrder(true);
        setLimitOrder(false);
        setOrderType("market");
    }

    const [inputPrecio, setInputPrecio] = useState("");
    const [inputCantidad, setInputCantidad] = useState("");
    const [inputTakeProfit, setInputTakeProfit] = useState("");
    const [inputProfitPrice, setInputProfitPrice] = useState("");
    const [inputStopLoss, setInputStopLoss] = useState("");
    const [inputStopPrice, setInputStopPrice] = useState("");

    const handleInputPrecio = (event) => {
        setInputPrecio(event.target.value);
    }

    const handleInputCantidad = (event) => {
        setInputCantidad(event.target.value);
    }

    const handleInputTakeProfit = (event) => {
        setInputTakeProfit(event.target.value);
    }

    const handleInputStopLoss = (event) => {
        setInputStopLoss(event.target.value);
    }

    const getInputTakeProfit = () => {
        setInputProfitPrice(+inputPrecio + inputPrecio * inputTakeProfit / 100);
    }

    const getInputStopLoss = () => {
        setInputStopPrice(inputPrecio - inputPrecio * inputStopLoss / 100);
    }

    const handleInputPrice = () => {
        getInputTakeProfit();
        getInputStopLoss();
    }

    const currentUser = useSelector(state => state.currentUser.data)
    const keys = useSelector(state => state.keys.data)

    async function sendCommand() {
        const headers = { 'token': currentUser.jwtToken };
        const selectedExchangeValue = selectedExchange.toLowerCase()
        const exchangeKeys = keys.find(exchange => exchange.exchange === selectedExchangeValue)
        const fullSelectedExchange = exchangesData.find(exchange => exchange.value === selectedExchangeValue)
        await axios
            .post(`${BACK_API}/buy-sell`, {
                public_key: exchangeKeys.publicKey,
                private_key: exchangeKeys.privateKey,
                password: exchangeKeys.password,
                exchange: fullSelectedExchange.value,
                symbol: selectedSymbol,
                amount: inputCantidad,
                price: inputPrecio,
                side: side,
                orderType: orderType,
                email: currentUser.email
            }, { headers })
            .then(() => {
                setOrderStatus("success")
                dispatch(getOrders())
                dispatch(getWallet(fullSelectedExchange.value))
            })
            .catch(() => setOrderStatus("error"))
    }

    const submit = async () => {
        setOrderStatus("loading")
        await sendCommand();
    }

    const handleCompleted = async () => {
        setOrderStatus("ready")
    }

    const canTrade = () => {
        if (orderType === "market" && !inputCantidad) {
            return false
        } else if (orderType === "limit" && (!inputCantidad || !inputPrecio)) {
            return false
        } else return true
    }

    return (
        <>
            <SoftBox>
                <ReactModal disableResize className={minimizedModal ? "flexible-modal-2" : "modal" && orderType === "market" ? "flexible-modal-3" : "modal" && orderType === "trailing" ? "flexible-modal-4" : "modal"} initWidth={290} initHeight={310} onRequestClose={modal} isOpen={modalIsOpen}>
                    <SoftButton className={"back-button"} size="small" variant="gradient" color="warning" onClick={() => setChartOpened(false)}>
                        <SoftTypography className={"close-button-text"} color="white">
                            Return
                        </SoftTypography>
                    </SoftButton>
                    <SoftTypography className={"market-label"} color="secondary">
                        {selectedSymbol}
                    </SoftTypography>
                    <SoftButton className={"close-button"} size="small" variant="gradient" color={"light"} iconOnly onClick={minimize}>
                        <Icon>
                            {minimizedModal &&
                                <OpenInFull />
                            }
                            {!minimizedModal &&
                                <CloseFullscreenIcon />
                            }
                        </Icon>
                    </SoftButton>
                    <SoftBox mt={2}>
                        {/*  <SoftButton size="small" onClick={handleClickLast} variant={last ? "gradient" : "outlined"} color="secondary" className={minimizedModal ? "hide" : "modal-buttons-top first-modal-button"}>
                            Last
                        </SoftButton> */}
                        <input onChange={handleInputPrecio} onBlur={handleInputPrice} className={minimizedModal || orderType === "market" ? "hide" : "input-precio"} type="number" placeholder={"Price"} size="small" />
                        {orderType === "trailing" ? (
                            <>
                                <Grid container>
                                    <Grid item lg={6}>
                                        <input onChange={handleInputTakeProfit} onBlur={getInputTakeProfit} className={minimizedModal ? "hide" : "input-precio"} type="number" placeholder="Take Profit" size="small" />
                                    </Grid>
                                    <Grid item lg={5.4}>
                                        <input disabled className={minimizedModal ? "hide" : "input-precio"} type="number" placeholder={inputProfitPrice} size="small" />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item lg={6}>
                                        <input onChange={handleInputStopLoss} onBlur={getInputStopLoss} className={minimizedModal ? "hide" : "input-precio"} type="number" placeholder="Stop Loss" size="small" />
                                    </Grid>
                                    <Grid item lg={5.4}>
                                        <input disabled className={minimizedModal ? "hide" : "input-precio"} type="number" placeholder={inputStopPrice} size="small" />
                                    </Grid>
                                </Grid>
                            </>) : (
                            ""
                        )
                        }
                        <input onChange={handleInputCantidad} className={minimizedModal ? "hide" : "input-cantidad"} type="number" placeholder="Amount" size="small" />
                    </SoftBox>
                    <Grid container spacing={2}>
                        <Grid item lg={6}>
                            <SoftButton onClick={handleClickSideBuy} variant={buy ? "gradient" : "outlined"} color="success" size="small" className={minimizedModal ? "hide" : "buy-button-main"}>
                                Buy
                            </SoftButton>
                        </Grid>
                        <Grid item lg={6}>
                            {orderType === "trailing" ? (
                                <SoftButton onClick={handleClickSideSell} variant={sell ? "gradient" : "outlined"} color="error" size="small" className={minimizedModal ? "hide" : "sell-button-main-disabled"}>
                                    Sell
                                </SoftButton>
                            ) : (
                                <SoftButton onClick={handleClickSideSell} variant={sell ? "gradient" : "outlined"} color="error" size="small" className={minimizedModal ? "hide" : "sell-button-main"}>
                                    Sell
                                </SoftButton>
                            )
                            }
                        </Grid>
                    </Grid>
                    <Grid container sx={{ justifyContent: 'space-evenly' }}>
                        <Grid item lg={4}>
                            <SoftButton fullwidth size="small" onClick={handleClickLimitOrder} variant={limitOrder ? "gradient" : "outlined"} color="secondary" className={minimizedModal ? "hide" : "order-type-button"}>
                                Limit
                            </SoftButton>
                        </Grid>
                        <Grid item lg={4}>
                            <SoftButton fullwidth size="small" onClick={handleClickMarketOrder} variant={marketOrder ? "gradient" : "outlined"} color="secondary" className={minimizedModal ? "hide" : "order-type-button"}>
                                Market
                            </SoftButton>
                        </Grid>
                        {/* <Grid item lg={4}>
                        <SoftButton fullwidth size="small" onClick={handleClickTrailingStopOrder} variant={trailingStopOrder ? "gradient" : "outlined"} color="secondary" className={minimizedModal ? "hide" : "order-type-button"}>
                            Trailing
                        </SoftButton>
                    </Grid> */}
                    </Grid>
                    {orderStatus === "ready" && <SoftButton disabled={!canTrade()} onClick={submit} className={minimizedModal ? "hide" : "order"} variant="gradient" color="info">
                        Trade
                    </SoftButton>}
                    {orderStatus === "success" && <center>
                        <Lottie loop={false} style={{ display: "inline-block", padding: "0px !important", margin: "0px !important", width: 54, height: "auto" }} onComplete={handleCompleted} animationData={success} />
                    </center>
                    }
                    {orderStatus === "error" && <center>
                        <Lottie loop={false} style={{ display: "inline-block", padding: "0px !important", margin: "0px !important", width: 54, height: "auto" }} onComplete={handleCompleted} animationData={failed} />
                    </center>
                    }
                    {orderStatus === "loading" && <Loader />}
                </ReactModal>
            </SoftBox>
            <SoftBox>
                <Card className="trading-view" id="trading">
                    <AdvancedRealTimeChart
                        container_id="trading"
                        style="0"
                        timezone={timeZone}
                        interval="D"
                        symbol={`${selectedExchange.toUpperCase()}:${selectedSymbol.replace("/", "")}`}
                        theme="dark"
                        autosize>
                    </AdvancedRealTimeChart>
                </Card>
            </SoftBox>
        </>
    );
}

export default FloatingOrder;
