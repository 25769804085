// Soft UI Dashboard React components
import SoftButton from "components/SoftButton";

// @mui material components
import { Grid } from "@mui/material";

export default function ChangeButton({ handleSubmit }) {

    return (
        <Grid item xs={6} md={4} xl={2}>
            <SoftButton
                onClick={() => handleSubmit()}
                style={{ marginTop: "25px" }}
                variant={"gradient"}
                color="warning"
                fullWidth
            >
                Update transaction
            </SoftButton>
        </Grid>
    );
}